import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Axios from "axios";

import { Accordion } from "react-bootstrap";
import { SentRequestButton } from "./modal/sentRequest";
import { Slider } from "./slider";

import logo from "../images/logoNou.png";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

export default function InfoHotel() {
    const location = useLocation();
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const [hotelData, setHotelData] = useState();
    const [countyId, setCountyId] = useState();
    const [countyName, setCountyName] = useState();
    const [cityId, setCityId] = useState();
    const [cityName, setCityName] = useState();
    const [wifi, setWifi] = useState();
    const [departments, setDepartments] = useState();
    const [noSolicitariPer24Hours, setSolicitariPer24Hours] = useState();
    const [payedOptions, setPayedOptions] = useState([]);
    const [events, setEvents] = useState([]);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const searchParams = new URLSearchParams(location.search);
    const qr = searchParams.get("qr");

    const hotelId = qr.split("/")[1];
    const roomId = qr.split("/")[0];

    const getHotel = async (hotelId) => new Promise((resolve, reject) => {

        Axios.get(`${BASE_URL}/getHotel/${hotelId}`)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ responseH: response.data.error });
                } else {
                    setHotelData(response.data);
                    setSolicitariPer24Hours(response.data[0].nrSolicitariPe24Ore);
                    setCountyId(parseInt(response.data[0].judetul));
                    setCityId(parseInt(response.data[0].localitatea));
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const getCountyById = async (countyId) => new Promise((resolve, reject) => {

        Axios.get(`${BASE_URL}/getCountyById/${countyId}`)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ response: response.data.error });
                } else {
                    setCountyName(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const getCityById = async (cityId) => new Promise((resolve, reject) => {

        Axios.get(`${BASE_URL}/getCityById/${cityId}`)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ response: response.data.error });
                } else {
                    setCityName(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const getWifi = async (hotelId) => new Promise((resolve, reject) => {
        Axios.get(`${BASE_URL}/getWifi/${hotelId}`)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ responseW: response.data.error });
                } else {
                    setWifi(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const depToServ = (departments) => {
        const depNameToServNames = {};
        departments.forEach((item) => {
            const { depName, servName, id } = item;
            if (depName) {
                if (!depNameToServNames[depName]) {
                    depNameToServNames[depName] = [];
                }
                depNameToServNames[depName].push(`${id}/${servName}`);
            }
        });
        setDepartments(depNameToServNames);
    };

    const getDepartmentsByHotelId = async (hotelId) => new Promise((resolve, reject) => {

        Axios.get(`${BASE_URL}/getDepartmentsByHotelId/${hotelId}`)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ responseD: response.data.error });
                } else {
                    depToServ(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const getPayedOptionsByIdHotel = async (hotelId) => new Promise((resolve, reject) => {

        Axios.post(`${BASE_URL}/getPayedOptions`, {
            idUnitate: hotelId
        })
            .then((response) => {
                if (response.data.error) {
                    setErrors({ responseD: response.data.error });
                } else {
                    setPayedOptions(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    const getEvents = async (hotelId) => new Promise((resolve, reject) => {

        Axios.post(`${BASE_URL}/getEvents`, {
            idUnitate: hotelId
        })
            .then((response) => {
                if (response.data.error) {
                    setErrors({ responseD: response.data.error });
                } else {
                    setEvents(response.data);
                    resolve(response);
                }
            })
            .catch((error) => {
                reject(error);
            });
    }
    );

    useEffect(() => {
        (async () => {
            if (hotelId) {
                await getHotel(hotelId);
                await getWifi(hotelId);
                await getDepartmentsByHotelId(hotelId);
                await getPayedOptionsByIdHotel(hotelId);
                await getEvents(hotelId);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (countyId)
                await getCountyById(countyId);
            if (cityId)
                await getCityById(cityId);
        })();
    }, [countyId, cityId]);

    return (
        <div className="uiContainer">
            <div className="uiContent">
                {hotelData?.map((val, key) => {
                    return (
                        <div key={key}>
                            <div className="uiTitle" style={{}}>
                                <h1><img src={logo} style={{ width: 50 }} /></h1>
                            </div>
                            <div className="uiTitle" style={{ marginBottom: "20px" }}>
                                <h1>{val.denumire}</h1>
                            </div>

                            {(val.name || val.telefon || val.email) && (
                                <>
                                    <div className="row" style={{ marginBottom: 20 }}>
                                        <i className="bi bi-geo-alt-fill" style={{ fontSize: 20 }}></i>
                                        <div className="col" style={{ marginTop: 8 }}>
                                            <span>{val.adresa}</span>,{" "}
                                            {cityName?.map((val, key) => {
                                                return (
                                                    <span key={key}>
                                                        {val.name}
                                                    </span>
                                                );
                                            })}, {" "}
                                            {countyName?.map((val, key) => {
                                                return (
                                                    <span key={key}>
                                                        {val.name}
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginBottom: 30 }}>
                                        <i className="bi bi-telephone-fill" style={{ fontSize: 20 }}></i><br />
                                        <div className="col" style={{ marginTop: 8 }}>
                                            <span>{val.telefon}</span>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    );
                })}
                {(hotelData && wifi && payedOptions.length > 0) && (<Slider hotelData={hotelData} wifiData={wifi} payedOptions={payedOptions} />)}

                {/* {hotelData?.map((val, _key) => {
                    return ( */}
                        {/* <> */}
                            {(events && events.length > 0) && (
                                <div className="card mb-3 eventsCardContainer">
                                    <div className="card-header eventsCardHeader ">
                                        <i className="bi bi-calendar-event-fill" style={{ fontSize: "18px" }}></i> Evenimente
                                    </div>
                                    <ul className="list-group list-group-flush">
                                        {events?.map((val2, key) => {
                                            return (
                                                <>
                                                    <Accordion defaultActiveKey="0" flush>
                                                        <Accordion.Item eventKey={key}>
                                                            <Accordion.Header>{val2.denumire}</Accordion.Header>
                                                            <Accordion.Body>
                                                                {val2.descriere}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </>
                                            );
                                        })}
                                    </ul>
                                </div>
                            )}

                            {(departments && Object.keys(departments).length > 0) && (
                                <div className="d-grid gap-2">
                                    <div className="title" style={{ marginTop: "20px" }}>
                                        <h4>Pentru a face o solicitare alege departamentul corespunzător.</h4>
                                    </div>
                                    {Object.keys(departments).map((depName, index) => (
                                        <Accordion key={index}>
                                            <Accordion.Item >
                                                <Accordion.Header>{depName}</Accordion.Header>
                                                {departments[depName].map((servName, subIndex) => {
                                                    const [servId, serviceName] = servName.split("/");
                                                    return (
                                                        (serviceName !== "null") && (
                                                            <Accordion.Body key={subIndex} className="departmentsButton">
                                                                <SentRequestButton
                                                                    servId={servId}
                                                                    serviceName={serviceName}
                                                                    hotelId={hotelId}
                                                                    roomId={roomId}
                                                                    noSolicitariPer24Hours={noSolicitariPer24Hours}
                                                                />
                                                            </Accordion.Body>
                                                        )
                                                    );
                                                })}
                                            </Accordion.Item>
                                        </Accordion>
                                    ))}
                                </div>
                            )
                            }
                        {/* </> */}
                {/* //     );
                // }
                // )} */}
            </div>
        </div>

    );
}

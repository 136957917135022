import React from "react";
import LoginComp from "./loginComp";
import TopMenu from "navigation/topMenu";
import Menu from "navigation/menu";
import Footer from "navigation/footer";

const Login = () => {
    return (
        <div>
            <TopMenu />
            <Menu />
            <LoginComp />
            <Footer />
        </div>
    );
};

export default Login;
// src/Notification.js
import { useEffect } from "react";

const NotificationPermission = () => {
  // const [subscription, setSubscription] = useState(null);
  // const [publicKey, setPublicKey] = useState("");
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  // const user = JSON.parse(localStorage.getItem("user"));
  // console.log("publicKey1", publicKey);

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          console.log("Permission for notifications granted!");
          //luam cheia publica de la server
          (async () => {
            const respKey = await fetch(`${BASE_URL}/public-key`, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
            const publicVapidKey = await respKey.json();
            console.log("publicKeyFromServer", publicVapidKey.publicKey);
            // if (publicVapidKey.publicKey != publicKey) {
            //   setPublicKey(publicVapidKey.publicKey);
            // }
            // Încărcăm serviciul worker pentru notificări push
            await registerServiceWorker(publicVapidKey.publicKey);
          })();
        }
      });
    }
  }, []);

  const registerServiceWorker = async (publicKey) => {
    console.log("service worker registered");
    const registrationReady = await navigator.serviceWorker.ready;
    console.log("registrationReady", registrationReady, publicKey);
    const registration = await navigator.serviceWorker.register("/service-worker.js");
    console.log("registration", registration, publicKey);
    console.log("publicKey2", publicKey);
    const subscription = await registration.pushManager.subscribe({ userVisibleOnly: true, applicationServerKey: publicKey });

    const p256dh = subscription.toJSON().keys.p256dh;
    const auth = subscription.toJSON().keys.auth;
    console.log("p256dh", p256dh);
    console.log("auth", auth);
    console.log("subscription", subscription);

    if (subscription && auth && p256dh) {
      // setSubscription(existingSubscription);
      console.log("existingSubscription", subscription);
      handleSendNotification(subscription, p256dh, auth);
    }
  };

  // console.log("subscription", subscription);

  const handleSendNotification = async (subscription, p256dh, auth) => {
    console.log("subscription111", subscription);
    // Verificăm dacă cheile 'keys' sunt definite în obiectul de abonament
    if (!subscription || !auth || !p256dh) {
      console.error("Keys 'auth' and 'p256dh' are required in subscription object.");
      return;
    }
    const payload = {
      endpoint: subscription.endpoint,
      p256dh: p256dh,
      auth: auth,
    };
    console.log("payload", payload);
    // Trimiteți abonamentul la server pentru a fi asociat cu utilizatorul autentificat
    await fetch(`${BASE_URL}/subscribe`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Subscription sent to server successfully.");
          // setSubscription(subscription);
        } else {
          console.error("Error sending subscription to server.");
        }
      })
      .catch((error) => {
        console.error("Error sending subscription to server.", error);
      });
    // await getSesizariByUserId();
  };

  // const getSesizariByUserId = async () => {
  //   const response = await fetch(`${BASE_URL}/getRequestsByUserId/${user.id}`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   });
  //   const data = await response.json();
  //   console.log("sesizari", data);
  //   if (data.length > 0) {
  //     await sendPushNotification(data);
  //   }
  // };

  // const sendPushNotification = async () => {
  //   const response = await fetch(`${BASE_URL}/send-notification`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({
  //       title: "Mesaj nou!",
  //       body: "Ai primit un mesaj de la un client.",
  //     }),
  //     // body: JSON.stringify({
  //     //   title: "Sesizare nouă!",
  //     //   body: "Ai primit o sesizare nouă de la .",
  //     // }),
  //   });

  //   if (response.ok) {
  //     console.log("Notificare push trimisă cu succes.");
  //   } else {
  //     console.error("Eroare la trimiterea notificării push.");
  //   }
  // };

  return null;
};

export default NotificationPermission;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";
// import TimePicker from "react-time-picker";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";

export const EditWifiButton = (props) => {
  console.log("props", props);
  const [checkIn, setCheckIn] = useState(props.checkIn);
  const [checkOut, setCheckOut] = useState(props.checkOut);
  // const [hotelName, setHotelName] = useState(props.hotelName);
  // const [idHotel, setIdHotel] = useState(props.idHotel);
  const [hotel, setHotel] = useState(props.idHotel);
  const [hotelList, setHotelList] = useState([]);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  console.log({ checkIn: checkIn, checkOut: checkOut });
  useEffect(() => {
    Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
      setHotelList(response.data);
    });
  }, []);

  const handleEdit = async (event) => {
    event.preventDefault();
    const dataToSend = {
      checkIn: checkIn,
      checkOut: checkOut,
      idUnitate: hotel,
      userId: user.id
    };

    const response = await Axios.post(`${BASE_URL}/editCheckInOut`, dataToSend);
    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setMessage(response.data.message);
      props.onChanges(counter => counter + 1);
      handleClose();
      return response;
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
      >
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage id="editcheckInOut.title" defaultMessage="Editeaza orele pentru checkIn/CheckOut " /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editcheckInOut.checkIn" defaultMessage="checkIn" /></Form.Label>
              <Form.Control
                type="time"
                placeholder="checkIn"
                value={checkIn}
                onChange={(e) => setCheckIn(e.target.value)}
              />
              {/* <TimePicker onChange={setCheckIn} value={checkIn} /> */}
            </Form.Group>
            {errors.checkIn && <p className="error">{errors.checkIn}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editcheckInOut.checkOut" defaultMessage="checkOut" /></Form.Label>
              <Form.Control
                type="time"
                placeholder="checkOut"
                value={checkOut}
                onChange={(e) => setCheckOut(e.target.value)}
              />
            </Form.Group>
            {errors.checkOut && <p className="error">{errors.checkOut}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editcheckInOut.hotel" defaultMessage="Hotel" /></Form.Label>
              <Form.Control
                as="select"
                value={hotel}
                onChange={(e) => setHotel(e.target.value)}
              >
                {hotelList.map((hotel, index) => {
                  return (
                    <option key={index} value={hotel.idUnitate}>{hotel.denumire}</option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editcheckInOut.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              <FormattedMessage id="editcheckInOut.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
          {message && <p className="success">{message}</p>}
        </Form>
      </Modal>
    </>

  );
};


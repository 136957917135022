import React, { useEffect, useState } from "react";
import Glider from "glider-js";
import "glider-js/glider.min.css";
import "./index.css";
// import checkInOut from "../images/ui/checkInOut.jpg";
// import restaurant from "../images/ui/restaurant.jpg";

export const Slider = (props) => {

  const createNewArrObjectForSlider = (props) => {
    const newArray = [];
    for (const obj of props.payedOptions) {
      if (obj.esteActiv === 1 && obj.optiune === "checkInOut") {
        const checkInOutObj = {
          checkIn: props.hotelData[0].checkIn,
          checkOut: props.hotelData[0].checkOut,
        };
        newArray.push(checkInOutObj);
      }
      if (obj.esteActiv === 1 && obj.optiune === "restaurant") {
        const restaurantObj = {
          breakfast: props.hotelData[0].breakfast,
          lunch: props.hotelData[0].lunch,
          dinner: props.hotelData[0].dinner,

        };
        newArray.push(restaurantObj);
      }
      if (obj.esteActiv === 1 && obj.optiune === "wifi") {
        for (const obj2 of props.wifiData) {
          const wifiData = {
            network: obj2.network,
            security: obj2.security,
            password: obj2.password,
          };
          newArray.push(wifiData);
        }
      }
    }
    return newArray;
  };

  const [shouldShowArrow, setShowArrow] = useState(false);
  const [sliderItems, setSliderItems] = useState(createNewArrObjectForSlider(props));

  const getSliderItems = async (props) => {
    const newSliderItems = createNewArrObjectForSlider(props);
    if (JSON.stringify(newSliderItems) !== JSON.stringify(sliderItems)) {
      setSliderItems(newSliderItems);
    }
  };

  useEffect(() => {
    getSliderItems(props);
  }, [props]);

  useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let glider = new Glider(document.getElementById("glider"), {
      showArrows: shouldShowArrow,
      slidesToScroll: "auto",
      slidesToShow: 1.5,
      rewind: true,
      duration: 0.25,
      draggable: true,
      dots: ".dots",
      exactWidth: true,
      arrows: {
        prev: "#prev",
        next: "#next"
      },
      responsive: [
        {
          breakpoint: 360,
          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            itemWidth: 210,
            duration: 0.25
          }
        }, {
          breakpoint: 775,

          settings: {
            slidesToShow: "auto",
            slidesToScroll: "auto",
            itemWidth: 250,
            duration: 0.25
          }
        }, {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            itemWidth: 250,
            duration: 0.25
          }
        }
      ]
    });
  }, [shouldShowArrow]);

  console.log(
    "finalOUT", {
    "sliderItems": sliderItems,
    "props.hotelData2": props.hotelData,
    "props.wifiData": props.wifiData,
  });

  return (
    <>
      {sliderItems?.length > 0 && (
        <div
          className="glider-contain"
          onMouseOver={() => setShowArrow(true)}
          onMouseLeave={() => setShowArrow(false)}
        >
          <div id="glider">
            {sliderItems?.map((val, index) => {
              return (
                <div
                  key={index}
                  className="theme"
                >
                  {(val.checkIn || val.checkOut) && (
                    <div>
                      <div className="headerCarousel " style={{ marginBottom: "20px" }}>
                        <i className="bi bi-building-fill" style={{ fontSize: "30px" }}></i> <br /><br />Check-in/Check-out
                      </div>
                      <div className="textStyle">Check-in de la ora {val.checkIn}</div>
                      <div className="textStyle">Check-out până la ora {val.checkOut}</div>
                    </div>
                  )}

                  {(val.breakfast || val.lunch || val.dinner) && (
                    <div>
                      <div className="headerCarousel " style={{ marginBottom: "20px" }}>
                        <i className="bi bi-emoji-smile" style={{ fontSize: "30px" }}></i> <br /><br />Program restaurant
                      </div>
                      <div className="textStyle">Mic dejun intre {val.breakfast}</div>
                      <div className="textStyle">Pranz intre {val.lunch}</div>
                      <div className="textStyle">Cina intre {val.dinner}</div>
                    </div>
                  )}

                  {val.network && (
                    <div>
                      <div className="headerCarousel " style={{ marginBottom: "20px" }}>
                        <i className="bi bi-wifi" style={{ fontSize: "30px" }}></i> <br /><br />Wifi
                      </div>
                      <div className="textStyle">Nume rețea: {val.network}</div>
                      <div className="textStyle">Parolă: {val.password}</div>
                      <div className="textStyle">Securitate: {val.security}</div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>

          <button
            aria-label="Previous"
            id="prev"
            className={`glider-prev ${!shouldShowArrow && "hidden"}`}
          >
            «
          </button>

          <button
            aria-label="Next"
            id="next"
            className={`glider-next ${!shouldShowArrow && "hidden"}`}
          >
            »
          </button>

          <div role="tablist" className="dots"></div>
        </div>
      )}

     {sliderItems?.length === 0 && (
        <>
          <div className="glider-contain">
            <div id="glider" style={{height:0}}></div>
          </div>
        </>
      )}
    </>
  );
};
import React from "react";
import { FormattedMessage } from "react-intl";
// import { Form, InputGroup } from "react-bootstrap";
import { EditRestaurantButton } from "./modal/edit";
import { DeleteRestaurantButton } from "./modal/delete";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const RestaurantsList = (props) => {

    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="">
                <h2><FormattedMessage id="restaurant.title" defaultMessage="Lista de restaurante" /></h2>
                <div className="table-responsive" style={{ paddingTop: 20 }} >
                    {props.restaurantsList.length != 0 && <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col"><FormattedMessage id="restaurant.restaurantName" defaultMessage="Denumirea" /></th>
                                <th scope="col"><FormattedMessage id="restaurant.breakfast" defaultMessage="Mic dejun" /></th>
                                <th scope="col"><FormattedMessage id="restaurant.lunch" defaultMessage="Pranz" /></th>
                                <th scope="col"><FormattedMessage id="restaurant.dinner" defaultMessage="Cina" /></th>
                                <th scope="col"><FormattedMessage id="restaurant.others" defaultMessage="Alte info" /></th>
                                <th scope="col"><FormattedMessage id="restaurant.hotel" defaultMessage="Hotel" /></th>
                                <th scope="col" className="thButtonSpace"><FormattedMessage id="restaurant.edit" defaultMessage="Edit" /></th>
                                <th scope="col" className="thButtonSpace"><FormattedMessage id="restaurant.delete" defaultMessage="Sterge" /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.restaurantsList.map((val, key) => {
                                return (
                                    <tr key={key}>
                                        <td>{key + 1}</td>
                                        <td>{val.restaurantName}</td>
                                        <td>{val.breakfast}</td>
                                        <td>{val.lunch}</td>
                                        <td>{val.dinner}</td>
                                        <td>{val.others}</td>
                                        <td>{val.hotel}</td>
                                        <td className="thButtonSpace">
                                            <EditRestaurantButton
                                                {...props}
                                                idRestaurant={val.idRestaurant}
                                                restaurantName={val.restaurantName}
                                                breakfast={val.breakfast}
                                                lunch={val.lunch}
                                                dinner={val.dinner}
                                                others={val.others}
                                                restaurantIdUnitate={val.hotel}
                                                onChanges={props.onChange}
                                            />
                                        </td>
                                        <td className="thButtonSpace">
                                            {/* {val.role === 1 && */}
                                            {/* <DeleteUserButton
                                            user={val.username}
                                            userId={val.id}
                                            hotel={val.unitName}
                                            onChange={setSubmitedChanges}
                                        /> */}
                                            <DeleteRestaurantButton
                                                idRestaurant={val.idRestaurant}
                                                restaurantName={val.restaurantName}
                                                onChanges={props.onChange}
                                            />
                                        </td>
                                    </tr>
                                );
                            }
                            )}
                        </tbody>
                    </table>
                    }
                    {props.restaurantsList.length == 0 && <div className="alert alert-danger" role="alert">
                        <FormattedMessage id="restaurant.noRestaurant" defaultMessage="Nu exista evenimente" />
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default RestaurantsList;

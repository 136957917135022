import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro";

import "bootstrap/dist/css/bootstrap.min.css";

registerLocale("ro", ro);

export const EditEventButton = (props) => {
  console.log({ "edit props": props });

  const [inputFields, setInputFields] = useState({
    eventName: props.eventName || "",
    eventDescriber: props.eventDescriber || "",
    eventDateTime: props.eventDateTime ? new Date(props.eventDateTime) : new Date(),
    idUnitate: props.eventIdUnitate || "",
  });
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleDateTimeChange = (date) => {
    setInputFields({
      ...inputFields,
      eventDateTime: date,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(`Field name: ${name}, Field value: ${value}`); // Adaugă acest log pentru debugging
    setInputFields({
      ...inputFields,
      [name]: value,
    });
  };

  const convertToUTC = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      return new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    }
    return null;
  };

  const filterPassedTime = (time) => {
    if (time instanceof Date && !isNaN(time)) {
      const currentDate = new Date();
      const selectedDate = new Date(time);
      return currentDate.getTime() < selectedDate.getTime();
    }
    return false;
  };

  const handleEdit = async (event) => {
    event.preventDefault();
    const utcDateTime = convertToUTC(inputFields.eventDateTime);

    if (!utcDateTime) {
      setErrors({ eventDateTime: "Invalid date" });
      return;
    }

    const dataToSend = {
      eventId: props.eventId,
      eventName: inputFields.eventName,
      eventDescriber: inputFields.eventDescriber,
      eventDateTime: utcDateTime.toISOString(),
      idUnitate: inputFields.idUnitate,
    };
    console.log("Data to send:", dataToSend); // Verificare obiect
    try {
      const response = await Axios.post(`${BASE_URL}/editEvent`, dataToSend);
      if (response.data.error) {
        setErrors({ response: response.data.error });
      } else {
        setMessage(response.data.message);
        props.onChanges((prevCounter) => prevCounter + 1);
        handleClose();
        return response;
      }
    } catch (error) {
      setErrors({ response: error.message });
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="editEvents.title" defaultMessage="Editeaza evenumentul " />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="events.eventName" defaultMessage="Denumire" />
              </Form.Label>
              <Form.Control
                type="text"
                name="eventName"
                defaultValue={inputFields.eventName}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.eventName && <p className="error">{errors.eventName}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="events.describer" defaultMessage="Descrierea" />
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={5}
                name="eventDescriber"
                defaultValue={inputFields.eventDescriber}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.checkOut && <p className="error">{errors.checkOut}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="events.dateTime" defaultMessage="Data" />
              </Form.Label><br />
              <DatePicker
                selected={inputFields.eventDateTime}
                onChange={handleDateTimeChange}
                name="eventDateTime"
                showTimeSelect
                dateFormat="dd MMMM yyyy HH:mm"
                timeIntervals={15}
                locale="ro"
                filterTime={filterPassedTime}
                className="form-control"
              />
            </Form.Group>
            {errors.eventDateTime && <p className="error">{errors.eventDateTime}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="events.hotel" defaultMessage="Hotel" />
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={inputFields.idUnitate}
                name="idUnitate"
                onChange={handleChange}
              >
                {props.hotelsList.map((hotel, index) => (
                  <option key={index} value={hotel.idUnitate}>{hotel.denumire}</option>
                ))}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editEvents.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" type="submit">
              <FormattedMessage id="editEvents.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
          {message && <p className="success">{message}</p>}
        </Form>
      </Modal>
    </>
  );
};

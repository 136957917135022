import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../../navigation/index.css";

const AddNewUserForm = (props) => {
    const [inputFields, setinputFields] = useState({
        nume: "",
        prenume: "",
        email: "",
        departament: "",
        hotel: "",
        role: "",
    });
    // eslint-disable-next-line
    const [errors, setErrors] = useState({});
    // const [hotelList, setHotelList] = useState([]);
    const [departamentsList, setDepartamentsList] = useState([]);

    const userData = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const nextStep = () => {
        navigate("/members/addServices");
    };

    const generatePass = () => {
        let pass = "";
        let str = "ABCDEFGHIJKLMNOPQRSTUVWXYZ" +
            "abcdefghijklmnopqrstuvwxyz0123456789@#$";
        for (let i = 1; i < 13; i++) {
            let char = Math.floor(Math.random()
                * str.length + 1);
            pass += str.charAt(char);
        }
        return pass;
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.nume) {
            errors.nume = <FormattedMessage id="addUser.numeError" defaultMessage="Adauga numele" />;
        }
        if (!inputValues.prenume) {
            errors.prenume = <FormattedMessage id="addUser.prenumeError" defaultMessage="Adauga prenumele" />;
        }
        if (!inputValues.email) {
            errors.email = <FormattedMessage id="addUser.emailError" defaultMessage="Email-ul este necesar" />;
        } else if (!/\S+@\S+\.\S+/.test(inputValues.email)) {
            errors.email = <FormattedMessage id="addUser.emailInvalidError" defaultMessage="Adresa de email nu este valida" />;
        }
        if (!inputValues.departament) {
            errors.departament = <FormattedMessage id="addUser.departamentError" defaultMessage="Selecteaza departamentul" />;
        }
        if (!inputValues.role) {
            errors.role = <FormattedMessage id="addUser.roleError" defaultMessage="Adauga rolul" />;
        }
        return errors;
    };

    useEffect(() => {
        Axios.post(`${BASE_URL}/getDepartamentList`, { userId: userData.id }).then((response) => {
            setDepartamentsList(response.data);
        });
    }, []);

    const handleChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateValues(inputFields);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {

            const depId = inputFields.departament.split("-")[0];
            const hotelId = inputFields.departament.split("-")[1];
            const data = {
                nume: inputFields.nume,
                prenume: inputFields.prenume,
                password: generatePass(),
                email: inputFields.email,
                idDepartament: depId,
                idHotel: hotelId,
                role: inputFields.role,
                userId: userData.id,
            };
            const response = await Axios.post(`${BASE_URL}/createNewUser`, data);

            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                props.setSubmitedChanges1(counter => counter + 1);
                return response;
            }
        }
    };

    return (
        <div className=" d-flex align-items-center justify-content-center">
            <div className="">
                <h1 className="text-center"><FormattedMessage id="addUser.title" defaultMessage="Adauga utilizator nou" /></h1>
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addUser.nume" defaultMessage="Nume" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="nume"
                            value={inputFields.nume}
                            onChange={handleChange}
                            className="inputLabel"
                            id="nume"
                        />
                    </InputGroup>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addUser.prenume" defaultMessage="Prenume" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="prenume"
                            value={inputFields.prenume}
                            onChange={handleChange}
                            className="inputLabel"
                            id="prenume"
                        />
                    </InputGroup>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addUser.email" defaultMessage="Email" /></InputGroup.Text>
                        <Form.Control
                            type="email"
                            // placeholder="Enter email"
                            name="email"
                            value={inputFields.email}
                            onChange={handleChange}
                            className="inputLabel"
                            id="email"
                        />
                    </InputGroup>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addUser.departament" defaultMessage="Departament" /></InputGroup.Text>
                        <Form.Control as="select" onChange={handleChange} name="departament" className="inputLabel" id="selectDepartmentId">
                            <option value="0"><FormattedMessage id="addUser.selectDep" defaultMessage="Selecteaza departamentul" /></option>
                            {departamentsList.map((val, key) => {
                                return (
                                    <option key={key} value={`${val.id}-${val.idUnitate}`}>{val.departName} - {val.unitName}</option>
                                );
                            })}
                        </Form.Control>
                    </InputGroup>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addUser.role" defaultMessage="Rol" /></InputGroup.Text>
                        <Form.Control as="select" onChange={handleChange} name="role" className="inputLabel" id="selectRoleId">
                            <option value="0"><FormattedMessage id="addUser.selectRole" defaultMessage="Selecteaza rolul" /></option>
                            <option value="1"><FormattedMessage id="addUser.manager" defaultMessage="Manager" /></option>
                            <option value="2"><FormattedMessage id="addUser.angajat" defaultMessage="Angajat" /></option>
                        </Form.Control>
                    </InputGroup>
                    <div className="form-group text-center" style={{ paddingTop: 20 }}>
                        <button type="submit" className="universalButton buttonUniv btn-rounded waves-effect">
                            <FormattedMessage
                                id="addUser.adauga_utilizator"
                                defaultMessage="Adauga utilizator"
                            />
                        </button>
                        <button className="universalButtonGreen buttonUnivGreen btn-rounded waves-effect" onClick={nextStep}>
                            <FormattedMessage
                                id="addUser.pasul_urmator"
                                defaultMessage="Pasul urmator"
                            />
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default AddNewUserForm;
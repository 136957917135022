import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Pagination from "@mui/material/Pagination";
import { FormattedMessage } from "react-intl";
import "./index.css";

const MyPagination = ({ page, pages, count, onPageChange }) => {
    const [currentPage, setCurrentPage] = useState(page);
    const [pageCount, setPageCount] = useState(pages);
    const [itemCount, setItemCount] = useState(count);

    useEffect(() => {
        setCurrentPage(page);
        setPageCount(pages);
        setItemCount(count);
    }, [page, pages, count]);

    const handlePageChange = (e, value) => {
        setCurrentPage(value);
        onPageChange(value);
    };

    return (
        <div className="pagination">
            <Pagination
                count={pageCount}
                page={currentPage}
                onChange={handlePageChange}
            />
            <div className="pagination__count">
                { itemCount > 1 && <span>{itemCount} <FormattedMessage id="pagination.pagini" defaultMessage="pagini" /></span> }
                { itemCount === 1 && <span>{itemCount} <FormattedMessage id="pagination.pagina" defaultMessage="pagină" /></span> }
            </div>
        </div>
    );
};

MyPagination.propTypes = {
    page: PropTypes.number.isRequired,
    pages: PropTypes.number.isRequired,
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
};

export default MyPagination;
import React from "react";
import InfoHotel from "./infoHotel";
import Menu from "../navigation/menu";
import TopMenu from "../navigation/topMenu";
import FooterMenu from "../navigation/footer";

const ClientPage = () => {

    return (
        <div>
            <TopMenu />
            <Menu />
            <InfoHotel/>
            <FooterMenu />
        </div>
    );
};

export default ClientPage;
import React, { useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro";

import "bootstrap/dist/css/bootstrap.min.css";

registerLocale("ro", ro);

export const EditRestaurantButton = (props) => {
  console.log({ "edit props": props });

  const [inputFields, setInputFields] = useState({
    restaurantName: props.restaurantName || "",
    breakfast: props.breakfast || "",
    lunch: props.lunch || "",
    dinner: props.dinner || "",
    others: props.others || "",
    idUnitate: props.restaurantIdUnitate || "",
  });

  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const validateValues = (inputValues) => {
    let errors = {};
    if (!inputValues.restaurantName) {
      errors.restaurantName = <FormattedMessage id="restaurant.restaurantNameErr" defaultMessage="Adauga denumirea restaurantului" />;
    }
    if (!inputValues.breakfast) {
      errors.breakfast = <FormattedMessage id="restaurant.breakfastErr" defaultMessage="Adauga programul pentru micul dejun" />;
    }
    if (!inputValues.lunch) {
      errors.lunch = <FormattedMessage id="restaurant.lunchErr" defaultMessage="Adauga programul pentru pranz" />;
    }
    if (!inputValues.dinner) {
      errors.dinner = <FormattedMessage id="restaurant.dinnerErr" defaultMessage="Adauga programul pentru cina" />;
    }
    if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
      errors.idUnitate = <FormattedMessage id="restaurant.selectHotel" defaultMessage="Alege hotelul" />;
    }
    return errors;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log(`Field name: ${name}, Field value: ${value}`); // Adaugă acest log pentru debugging
    setInputFields({
      ...inputFields,
      [name]: value,
    });
  };

  const handleEdit = async (event) => {
    event.preventDefault();

    const errors = validateValues(inputFields);
    setErrors(errors);

    if (Object.keys(errors).length === 0) {

      const dataToSend = {
        idRestaurant: props.idRestaurant,
        restaurantName: inputFields.restaurantName,
        breakfast: inputFields.breakfast,
        lunch: inputFields.lunch,
        dinner: inputFields.dinner,
        others: inputFields.others,
        idUnitate: inputFields.idUnitate,
      };
      console.log("Data to send:", dataToSend); // Verificare obiect
      try {
        const response = await Axios.post(`${BASE_URL}/editRestaurant`, dataToSend);
        if (response.data.error) {
          setErrors({ response: response.data.error });
        } else {
          setMessage(response.data.message);
          props.onChanges((prevCounter) => prevCounter + 1);
          handleClose();
          return response;
        }
      } catch (error) {
        setErrors({ response: error.message });
      }
    }
  };

  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="restaurant.editTitle" defaultMessage="Editeaza restaurantul  " /> {props.restaurantName}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.restaurantName" defaultMessage="Denumire" />
              </Form.Label>
              <Form.Control
                type="text"
                name="restaurantName"
                defaultValue={inputFields.restaurantName}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.eventName && <p className="error">{errors.eventName}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.breakfast" defaultMessage="Mic dejun" />
              </Form.Label>
              <Form.Control
                type="text"
                name="breakfast"
                defaultValue={inputFields.breakfast}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.breakfast && <p className="error">{errors.breakfast}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.lunch" defaultMessage="Pranz" />
              </Form.Label>
              <Form.Control
                type="text"
                name="lunch"
                defaultValue={inputFields.lunch}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.lunch && <p className="error">{errors.lunch}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.dinner" defaultMessage="Cina" />
              </Form.Label>
              <Form.Control
                type="text"
                name="dinner"
                defaultValue={inputFields.dinner}
                onChange={handleChange}
              />
            </Form.Group>
            {errors.dinner && <p className="error">{errors.dinner}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.others" defaultMessage="Altele" />
              </Form.Label>
              <Form.Control
                type="text"
                name="others"
                defaultValue={inputFields.others}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formBasicCamera">
              <Form.Label>
                <FormattedMessage id="restaurant.hotel" defaultMessage="Hotel" />
              </Form.Label>
              <Form.Control
                as="select"
                defaultValue={inputFields.idUnitate}
                name="idUnitate"
                onChange={handleChange}
              >
                {props.hotelsList.map((hotel, index) => (
                  <option key={index} value={hotel.idUnitate}>{hotel.denumire}</option>
                ))}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="restaurant.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" type="submit">
              <FormattedMessage id="restaurant.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
          {message && <p className="success">{message}</p>}
        </Form>
      </Modal>
    </>
  );
};

import React, { useEffect, useState } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const CheckInOutForm = (props) => {
    const [inputFields, setinputFields] = useState({
        checkIn: "",
        checkOut: "",
        idUnitate: "",
    });
    const [errors, setErrors] = useState({});
    const [hotelList, setHotelList] = useState([]);

    const user = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.checkIn) {
            errors.checkIn = <FormattedMessage id="checkInOut.addCheckInErr" defaultMessage="Adauga ora de check-in!" />;
        }
        if (!inputValues.checkOut) {
            errors.checkOut = <FormattedMessage id="checkInOut.addCheckOutErr" defaultMessage="Adauga ora de check-out!" />;
        }
        if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
            errors.idUnitate = <FormattedMessage id="checkInOut.selectHotel" defaultMessage="Alege hotelul" />;
        }
        return errors;
    };

    const getHotelList = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/getHotelList`, userData);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setHotelList(response.data);
        }
    };

    const userData = {
        userId: user.id,
    };

    useEffect(() => {
        (async () => {
            getHotelList(userData);
        }
        )();
    }, [props.onChange]);

    const handleChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("handle");
        const errors = validateValues(inputFields);
        setErrors(errors);
        if (Object.keys(errors).length === 0) {
            Axios.post(`${BASE_URL}/addCheckInOut`, {
                checkIn: inputFields.checkIn,
                checkOut: inputFields.checkOut,
                idUnitate: inputFields.idUnitate,
            }).then((response) => {
                props.setSubmitedChanges(counter => counter + 1);
                console.log("response", response);
            });
        }
    };

    return (
        <div className=" d-flex align-items-center justify-content-center">
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="checkInOut.checkIn" defaultMessage="Check-In" />
                        </InputGroup.Text>
                        <Form.Control
                            type="time"
                            name="checkIn"
                            value={inputFields.checkIn}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>
                    {errors.checkIn && <p className="error">{errors.checkIn}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="checkInOut.checkOut" defaultMessage="Check-Out" />
                        </InputGroup.Text>
                        <Form.Control
                            type="time"
                            name="checkOut"
                            value={inputFields.checkOut}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>
                    {errors.checkOut && <p className="error">{errors.checkOut}</p>}
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="checkInOut.idUnitate" defaultMessage="Hotelul" /></InputGroup.Text>
                        <Form.Select name="idUnitate" onChange={handleChange}>
                            <option value="0"><FormattedMessage id="checkInOut.selectHotel" defaultMessage="Alege hotelul" /></option>
                            {hotelList.map((hotel, key) => (
                                <option key={key} value={hotel.idUnitate}>{hotel.denumire}</option>
                            ))}
                        </Form.Select>
                    </InputGroup>
                    {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
                    <button
                        type="submit"
                        style={{ marginTop: 10 }}
                        className="universalButton buttonUniv btn-rounded waves-effect waves-light"
                    >
                        <FormattedMessage id="checkInOut.save" defaultMessage="Salveaza" />
                    </button>
                </Form>
            </div>
        </div>
    );
};

export default CheckInOutForm;
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

import "bootstrap-icons/font/bootstrap-icons.css";

export const SentRequestButton = (props) => {
    const [showModalMesajConfirmare, setShowModalMesajConfirmare] = useState(false);
    const [showModalMesajConfirmareTrimitere, setShowModalMesajConfirmareTrimitere] = useState(false);
    const [showModalMesajEroare, setShowModalMesajEroare] = useState(false);
    const [stareSolicitare, setStareSolicitare] = useState("waiting");
    const [submitting, setSubmitting] = useState(false);
    const [intervalBetweenRequests, setIntervalBetweenRequests] = useState(0);
    //eslint-disable-next-line
    const [errors, setErrors] = useState(null);

    const handleCloseModalMesajConfirmare = () => setShowModalMesajConfirmare(false);
    const handleShowModalMesajConfirmare = () => setShowModalMesajConfirmare(true);
    const handleCloseModalMesajConfirmareTrimitere = () => {
                                                                setShowModalMesajConfirmareTrimitere(false);
                                                                setShowModalMesajConfirmare(false);
                                                                setStareSolicitare("exist");
                                                                setSubmitting(false);
                                                            };
    const handleCloseModalMesajEroare = () => setShowModalMesajEroare(false);
    const handleShowModalMesajEroare = () => setShowModalMesajEroare(true);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    if(intervalBetweenRequests === 0){
        const getIntervalBetweenRequests = 24/props.noSolicitariPer24Hours;
        setIntervalBetweenRequests(getIntervalBetweenRequests);
    }

    const handleSubmit = async(p) => {
        p.preventDefault();
        await verifyIfRequestExists();
    };

    const verifyIfRequestExists = async() => {
        const data = {
            servId: props.servId,
            hotelId: props.hotelId,
            roomId: props.roomId,
        };

        const resp = await Axios.post(`${BASE_URL}/verifyRequest`, data);

        if(resp.data.length >= props.noSolicitariPer24Hours) // daca exista deja o solicitare pentru acest serviciu
        {
            // alert('Nu mai poti trimite solicitari pentru aceasta camera');
            setStareSolicitare("exist");
            setShowModalMesajConfirmare(false);
            setShowModalMesajConfirmareTrimitere(false);
            setShowModalMesajEroare(true);
            setSubmitting(false);
        }
        else{
            setStareSolicitare("notExist");
            setShowModalMesajConfirmare(true);
            setShowModalMesajConfirmareTrimitere(true);
            setShowModalMesajEroare(false);
            setSubmitting(true);
            finishSubmit();
        }
    };

    const finishSubmit = async() => {
        const data = {
            servId: props.servId,
            hotelId: props.hotelId,
            roomId: props.roomId,
        };

        await Axios.post(`${BASE_URL}/sentRequest`, data).then((res) => {
            if (res.status === 200) {
                // console.log({'Succesfully sent request': res.status});
                setErrors(null);

            } else {
                // console.log({'Failed to send request': res.status});
                setErrors(res.data.message);
            }
        });
    };

    return (
        <>
           <div
            className="d-flex justify-content-center"
            onClick={
                (stareSolicitare === "waiting" && !submitting)
                  ? handleShowModalMesajConfirmare
                  : (stareSolicitare === "exist" && !submitting)
                    ? handleShowModalMesajEroare
                    : null
              }
        >
                <i className="bi bi-send-plus" style={{paddingRight:10, paddingLeft:15}}></i>{" "}{props.serviceName}
            </div>
        {(stareSolicitare==="waiting" && !submitting) && (
            <Modal show={showModalMesajConfirmare}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header >
                        <Modal.Title>
                            <div className="d-flex justify-content-center">
                                <i className="bi bi-send-plus" style={{color: "#ff5825", fontSize: 45}}></i><br />
                            </div>
                            <FormattedMessage id="requests.sendRequest" defaultMessage={"Ești sigur că vrei sa trimiti solicitarea referitoare la "} />{" "}{props.serviceName}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseModalMesajConfirmare}>
                            <FormattedMessage id="requests.no" defaultMessage={"Nu"} />
                        </Button>
                        <Button variant="success" onClick={handleCloseModalMesajConfirmare} type="submit">
                            <FormattedMessage id="requests.yes" defaultMessage={"Da"} />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            )}
            {(stareSolicitare==="notExist" && showModalMesajConfirmareTrimitere) && (
                <Modal show={submitting} >
                    <Modal.Header >
                        <Modal.Title>
                            <div className="d-flex justify-content-center">
                                <i className="bi bi-check-circle" style={{color: "#ff5825", fontSize: 45}}></i><br />
                            </div>
                            <FormattedMessage id="requests.successMessage" defaultMessage={"Solicitarea a fost trimisă cu succes!"} />
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalMesajConfirmareTrimitere}>
                            <FormattedMessage id="requests.ok" defaultMessage={"Ok"} />
                        </Button>
                    </Modal.Footer>
                </Modal>
           ) }

           {(stareSolicitare==="exist" && !submitting) && (
                <Modal show={showModalMesajEroare}>
                    <Modal.Header >
                        <Modal.Title>
                            <div className="d-flex justify-content-center">
                                <i className="bi bi-exclamation-circle" style={{color: "#ff5825", fontSize: 45}}></i><br />
                            </div>
                            <FormattedMessage id="requests.errorMessage" defaultMessage={"Nu mai poti trimite solicitari pentru acest serviciu in urmatoarele !"} />{intervalBetweenRequests} ore
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="success" onClick={handleCloseModalMesajEroare}>
                            <FormattedMessage id="requests.ok" defaultMessage={"Ok"} />
                        </Button>
                    </Modal.Footer>
                </Modal>
           ) }
        </>
    );
};
import React from "react";
import { useNavigate } from "react-router-dom";

const Cancel = () => {

  const navigate = useNavigate();
  setTimeout(() => {
    navigate("/members/financial");
  }, 5000);

  return (
    <div>
      <h1>Plata a fost anulată</h1>
      <p>Dacă ai întrebări sau ai nevoie de asistență, te rugăm să ne contactezi.</p>
      {/* Poți adăuga butoane sau link-uri către alte părți ale aplicației sau pagini relevante */}
    </div>
  );
};

export default Cancel;

import React from "react";
import { Document, Page, Text, View, StyleSheet } from "@react-pdf/renderer";
// import {PDFViewer} from "@react-pdf/renderer";
import download from "downloadjs";
import { pdf } from "@react-pdf/renderer";
import "./index.css";

const Invoice = (props) => {
  // console.log("props", props);
  // Stiluri pentru factura
  const styles = StyleSheet.create({
    page: {
      // flexDirection: "row",
      padding: 20,
      // border: "1px solid #ffcccc",
      display: "flex",
    },
    topRow: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
    sectionLeft: {
      margin: 10,
      padding: 10,
      flexGrow: 0,
      // border: "1px solid #ffcccc",
      height: 150,
      width: 200,
    },
    sectionRight: {
      margin: 10,
      padding: 10,
      flexGrow: 0,
      // border: "1px solid #ffcccc",
      height: 150,
      width: 250,
      marginTop: 0,
    },
    title: {
      fontSize: 14,
      fontWeight: "bold",
      marginBottom: 10,
    },
    titluLista: {
      fontSize: 12,
      fontWeight: "bold",
      marginBottom: 10,
      textAlign: "center",
      alignContent: "center",
      justifyContent: "space-between",
    },
    elementeFacturare: {
      display: "flex",
      flexDirection: "row",
      // justifyContent: "space-between",
      marginBottom: 10,
      fontSize: 10,
      marginLeft: 10,
      marginRight: 10,
      marginTop: 10,
      // border: "1px solid #ffcccc",
      justifyContent: "center",
      alignItems: "center",
    },
    tableHeader: {
      flexDirection: "row",
      borderBottomWidth: 1,
      borderBottomColor: "#000",
      alignItems: "center",
      marginBottom: 5,
      width: 500,
      alignContent: "center",
    },
    tableRow: {
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 5,
      width: 500,
      alignContent: "center",
    },
    tableCell: {
      // borderRightWidth: 1,
      // borderRightColor: "#000",
      paddingRight: 5,
      fontSize: 10,
      width: 450,
      flex: 1, // Setează flexibilitatea pentru a ocupa spațiul disponibil
      textAlign: "center",
    },
    tableCell1: {
      // borderRightWidth: 1,
      // borderRightColor: "#000",
      // paddingRight: 5,
      fontSize: 10,
      width: 50,
      flex: 1,
      textAlign: "center",
    },
    tableCellCantitatea: {
      paddingRight: 5,
      fontSize: 10,
      width: 100,
      flex: 1,
      textAlign: "center",
    },
    lastTableCell: {
      borderRightWidth: 0,
    },
    infoClient: {
      marginBottom: 5,
      fontSize: 10,
      textAlign: "left",
    },
    // email: {
    //   marginBottom: 5,
    //   fontSize: 10,
    //   textAlign: "left",
    // }
  });

  const InvoiceDocument = () => (
    <Document>
      <Page style={styles.page}>
        <View style={styles.topRow}>
          {/* Partea stângă a paginii pentru datele clientului */}
          <View style={[styles.sectionLeft, { marginRight: 20 }]}>
            <Text style={styles.title}>Facturat catre</Text>
            <Text style={styles.infoClient}>Nume Client: {props.societate}</Text>
            <Text style={styles.infoClient}>Adresa: {props.adresa_societate}</Text>
            <Text style={styles.infoClient}>Telefon: {props.telefon_societate}</Text>
            <Text style={styles.infoClient}>Email: {props.email_societate}</Text>
            <Text style={styles.infoClient}>Cod fiscal: {props.cui}</Text>
            <Text style={styles.infoClient}>Reg. Comertului: {props.nrc}</Text>
          </View>

          {/* Partea dreaptă a paginii pentru datele furnizorului și elemente de facturare */}
          <View style={styles.sectionRight}>
            {/* Header pentru datele furnizorului */}
            <View style={{ marginBottom: 20 }}>
              <Text style={styles.title}>{props.invoiceType}: QRA {props.invoiceId}</Text>
              <Text style={styles.title}>Data emiterii: {props.createdAt}</Text>
            </View>

            {/* Informații despre companie */}
            <View style={styles.address}>
              <Text style={styles.infoClient}>Nume Companie: Companie Exemplu</Text>
              <Text style={styles.infoClient}>Adresa: Strada Exemplu, Oras Exemplu</Text>
              <Text style={styles.infoClient}>Telefon: 123-456-7890</Text>
              <Text style={styles.infoClient}>Email: contact@companie.com</Text>
            </View>
          </View>
        </View>
        <View style={styles.elementeFacturare}>
          {/* Elemente de facturare */}
          <View>
            <Text style={styles.titluLista}>Lista de servicii</Text>

            {/* Tabel pentru detalii */}
            <View style={styles.tableHeader}>
              <Text style={styles.tableCell1}>Nr. crt.</Text>
              <Text style={styles.tableCell}>Produs</Text>
              <Text style={styles.tableCellCantitatea}>Cantitate</Text>
              <Text style={styles.tableCell}>Preț unitar</Text>
              <Text style={styles.tableCell}>Discount</Text>
              <Text style={styles.lastTableCell}>Total</Text>
            </View>

            {/* Rânduri ale tabelului */}
            <View style={styles.tableRow}>
              <Text style={styles.tableCell1}>1</Text>
              <Text style={styles.tableCell}>Servicii qrAlert.ro - {props.invoicePeriod}</Text>
              <Text style={styles.tableCellCantitatea}>1</Text>
              <Text style={styles.tableCell}>{props.price}ron</Text>
              <Text style={styles.tableCell}>0.00</Text>
              <Text style={styles.lastTableCell}>{props.price}ron</Text>
            </View>

          </View>

          {/* Total factura */}
        </View>
        <View style={{ marginTop: 20, alignContent: "right", textAlign: "right", paddingRight: 25 }}>
          <Text style={styles.title}>Total: {props.price}ron</Text>
        </View>
      </Page>
    </Document>
  );

  const downloadInvoice = async () => {
    const pdfBytes = await pdf(InvoiceDocument()).toBlob();
    const pdfBlob = new Blob([pdfBytes], { type: "application/pdf" });
    download(pdfBlob, `${props.invoiceType}_QRA_${props.invoiceId}`, "application/pdf");
  };

  return (
    <div>
      {/* <PDFViewer width="500" height="600">
        <InvoiceDocument />
      </PDFViewer> */}
      <button className="buttonDwnInvoice" onClick={downloadInvoice}><i className="bi bi-download" style={{ fontSize: 20 }}></i></button>
    </div>
  );
};

export default Invoice;

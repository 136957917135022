import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../App"; // Asigură-te că calea este corectă
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import "bootstrap/dist/css/bootstrap.min.css";
import logoNou from "../images/logoNouRotundMaro.png";

const Menu = () => {
    const { user, setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const handleLogout = (event) => {
        event.preventDefault(); // Previne comportamentul implicit al href
        localStorage.removeItem("user");
        setUser(null);
        navigate("/login");
    };

    // Meniul de bază
    const baseMenu = [
        { key: 1, name: "Acasa", path: "/" },
    ];

    // Meniul pentru utilizatori simpli
    const userMenu = [
        { key: 2, name: "Panou de control", path: "/members/dashboard" },
        {
            key: 3, name: "Contul meu", items: [
                ...(user && (user.role === 0 || user.role === 1) ? [{ key: 4, name: "Financiar", path: "/members/financial" }] : []),
                { key: 5, name: "Setari", path: "/members/settings" }
            ]
        },
    ];

    // Meniul pentru admini și superadmini
    const adminMenu = [
        {
            key: 8, name: "Configurare", items: [
                { key: 9, name: "Hoteluri", path: "/members/addNewHotel" },
                { key: 10, name: "Departamente", path: "/members/addNewDepartament" },
                { key: 11, name: "Utilizatori", path: "/members/addNewUser" },
                { key: 12, name: "Servicii", path: "/members/addServices" },
                { key: 13, name: "Camere", path: "/members/createQrCode" },
                { key: 14, name: "Optiuni cu plata", path: "/members/payedOptions" }
            ]
        },
        {
            key: 15, name: "Optionale", items: [
                { key: 16, name: "Retele Wi-Fi", path: "/members/wifi" },
                { key: 17, name: "Recenzii", path: "/members/reviews" },
                { key: 18, name: "Check-in/out", path: "/members/checkInOut" },
                { key: 19, name: "Evenimente", path: "/members/events" },
                { key: 20, name: "Restaurant", path: "/members/restaurant" }
            ]
        }
    ];

    // Meniul final combinat
    let fullMenu = [
        ...baseMenu,
        ...(user && (user.role === 0 || user.role === 1 || user.role === 2) ? userMenu : []),
        ...(user && (user.role === 0 || user.role === 1) ? adminMenu : []),
        { key: 6, name: "Despre", path: "/despre" },
        { key: 7, name: "Contact", path: "/contact" },
        ...(user ? [
            { key: 21, name: "Logout", path: "#", onClick: handleLogout }
        ] : [
            { key: 22, name: "Login", path: "/login" }
        ])
    ];

    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand href="#home">
                    <img
                        alt=""
                        src={logoNou}
                        width="35"
                        height="35"
                        className="d-inline-block align-top"
                    />{" "}
                    <span style={{ marginLeft: 15, fontSize: 28, fontWeight: "bold", color: "#ff5825" }}>qrAlert.ro</span>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto justify-content-end">
                        {fullMenu.map(item => (
                            item.items ? (
                                <NavDropdown key={item.key} title={item.name} id={`nav-dropdown-${item.key}`}>
                                    {item.items.map(subItem => (
                                        <NavDropdown.Item key={subItem.key} href={subItem.path}>{subItem.name}</NavDropdown.Item>
                                    ))}
                                </NavDropdown>
                            ) : (
                                <Nav.Link key={item.key} href={item.path} onClick={item.onClick}>{item.name}</Nav.Link>
                            )
                        ))}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default Menu;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export const EditWifiButton = (props) => {

  const [network, setNetwork] = useState(props.network);
  const [security, setSecurity] = useState(props.security);
  const [password, setPassword] = useState(props.password);
  // const [idHotel, setIdHotel] = useState(props.idHotel);
  const [hotel, setHotel] = useState(props.idHotel);
  const [hotelList, setHotelList] = useState([]);
  const [message, setMessage] = useState("");
  const [errors, setErrors] = useState({});
  const [show, setShow] = useState(false);

  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
      setHotelList(response.data);
    });
  }, []);

  const handleEdit = async (event) => {
    event.preventDefault();
    const dataToSend = {
      wifiId: props.idWifi,
      network: network,
      security: security,
      password: password,
      idHotel: hotel,
      userId: user.id
    };

    const response = await Axios.post(`${BASE_URL}/editWifi`, dataToSend);
    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {
      setMessage(response.data.message);
      props.onChanges(counter => counter + 1);
      handleClose();
      return response;
    }
  };

  return (
    <>
      <Button
        variant="primary"
        onClick={handleShow}
      >
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleEdit}>
          <Modal.Header closeButton>
            <Modal.Title><FormattedMessage id="editWifi.title" defaultMessage="Editeaza reteaua WiFi " /> {props.network}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editWifi.network" defaultMessage="Nume retea WiFi" /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Nume retea WiFi"
                value={network}
                onChange={(e) => setNetwork(e.target.value)}
              />
            </Form.Group>
            {errors.network && <p className="error">{errors.network}</p>}

            <Form.Group>
              <Form.Label><FormattedMessage id="editWifi.security" defaultMessage="Securitate" /></Form.Label>
              <Form.Control
                as="select"
                name="security"
                value={security}
                onChange={(e) => setSecurity(e.target.value)}
              >
                <option value="0"><FormattedMessage id="editWifi.securityType" defaultMessage="Tipul de securitate" /></option>
                <option value="OPEN">OPEN</option>
                <option value="WEP">WEP</option>
                <option value="WPA">WPA</option>
                <option value="WPA2">WPA2</option>
                <option value="WPA3">WPA3</option>
              </Form.Control>
            </Form.Group>
            {errors.security && <p className="error">{errors.security}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editWifi.password" defaultMessage="Parola" /></Form.Label>
              <Form.Control
                type="text"
                placeholder="Parola"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Group>
            {errors.password && <p className="error">{errors.password}</p>}

            <Form.Group controlId="formBasicCamera">
              <Form.Label><FormattedMessage id="editWifi.hotel" defaultMessage="Hotel" /></Form.Label>
              <Form.Control
                as="select"
                value={hotel}
                onChange={(e) => setHotel(e.target.value)}
              >
                {hotelList.map((hotel, index) => {
                  return (
                    <option key={index} value={hotel.idUnitate}>{hotel.denumire}</option>
                  );
                })}
              </Form.Control>
            </Form.Group>
            {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              <FormattedMessage id="editWifi.close" defaultMessage="Inchide" />
            </Button>
            <Button variant="success" onClick={handleClose} type="submit">
              <FormattedMessage id="editWifi.save" defaultMessage="Salveaza" />
            </Button>
          </Modal.Footer>
          {message && <p className="success">{message}</p>}
        </Form>
      </Modal>
    </>

  );
};


// SuccessPage.js
import FooterMenu from "navigation/footer";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const SuccessPage = () => {
  const navigate = useNavigate();
  const [paymentIntentState, setPaymentIntentState] = React.useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const urlParams = new URLSearchParams(window.location.search);
  const sessionId = urlParams.get("session_id");
  const invoiceId = urlParams.get("invoiceId");

  const fetchData = async () => {
    const fetchUrl = `${BASE_URL}/get-session-info?session_id=${sessionId}`;
    const response = await fetch(fetchUrl);
    const data = await response.json();
    console.log("data", data);
    const { paymentIntent } = data;
    if (paymentIntent !== paymentIntentState) {
      setPaymentIntentState(paymentIntent);
    }
    console.log("Moneda:", paymentIntent.currency);
    console.log("ID:", paymentIntent.id);
  };

  useEffect(() => {
    (async () => {
      fetchData();
    }
    )();
  }, []);

  if (paymentIntentState.status === "succeeded") {
    (async () => {
      const dataToSend = {
        invoiceId: invoiceId,
        sessionId: sessionId,
        paymentState: 1,
      };
      const fetchUrl = `${BASE_URL}/updatePaymentStatus`;
      try {
        //eslint-disable-next-line
        const response = await fetch(fetchUrl, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        });
        //eslint-disable-next-line
        const data = await response.json();
        // console.log("data2", data);
        setTimeout(() => {
          navigate("/members/financial");
        }, 5000);

      } catch (error) {
        console.error("Eroare la actualizarea stării plății:", error);
      }
    })();
  }

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstSuccessContainer">
      </div>
      <div className="containerSuccessNetworksForm">
        <div className="successContainer" data-aos="fade-in">
          <h2>
            Plata a fost efectuată cu succes!
            {/* <FormattedMessage id="wifi.mainTitle" defaultMessage="Pagina WiFi" /> */}
          </h2>
          <h6>
            {/* <FormattedMessage id="wifi.subtitle" defaultMessage="adauga o retea noua de wifi" /> */}
          </h6>
        </div>
        <p>ID-ul tranzacției: {paymentIntentState.id}</p>
        <p>Suma plătită: {paymentIntentState.amount_received / 100} {paymentIntentState.currency}</p>
        {/* Alte detalii despre tranzacție sau mesaje de mulțumire pot fi adăugate aici*/}
        <p>Mulțumim pentru plată!</p>
        <p>Veți fi redirecționat către pagina de facturi în 5 secunde.</p>
      </div>
      <FooterMenu />
    </div>
  );
};

export default SuccessPage;

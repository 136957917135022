import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

export const DeleteEventButton = (props) => {
    const [show, setShow] = useState(false);
    //eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});

    const user = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const handleDelete = () => {
        const data = {
            eventId: props.eventId,
            userId: user.id,
        };

        Axios.post(`${BASE_URL}/deleteEvent`, data)
            .then((response) => {
                if (response.data.error) {
                    setErrors({ response: response.data.error });
                } else {
                    props.onChanges((prevCounter) => prevCounter + 1);
                    setShow(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div>
            <Button variant="danger" onClick={() => setShow(true)}>
                <i className="bi bi-trash3"></i>
            </Button>
            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title><FormattedMessage id="delEvent.deleteMess" defaultMessage="Sterge evenimentul" /></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormattedMessage id="delEvent.confirm" defaultMessage="Esti sigur ca vrei sa stergi evenimentul?" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShow(false)}>
                        <FormattedMessage id="delEvent.close" defaultMessage="Inchide" />
                    </Button>
                    <Button variant="danger" onClick={handleDelete}>
                        <FormattedMessage id="delEvent.delete" defaultMessage="Sterge" />
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

import React, { useContext } from "react";
import { UserContext } from "../../App";
import { useState } from "react";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import AddNewUserForm from "./addUserForm";
import { GetUsersList } from "./usersList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../../navigation/index.css";

const AddNewUser = () => {
    const [submitedChanges, setSubmitedChanges] = useState(0);

    const { user } = useContext(UserContext);
    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <AddNewUserForm setSubmitedChanges1={setSubmitedChanges} />
                    <hr />
                    <GetUsersList submitedChanges2={submitedChanges} />
                </div>
            </div>
        </div>
    );
};

export default AddNewUser;
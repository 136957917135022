import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import CardRoom from "./cardRoom";

import "./index.css";
import "navigation/index.css";

const Dashboard = () => {
    const [errors, setErrors] = useState({});
    const [rooms, setRooms] = useState([]);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));

    const getListbyUserId = async (userId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getQrListbyUserId/${userId}`);
            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                setRooms(response.data);
            }
        } catch (error) {
            setErrors({ response: error.message });
        }
    };

    const getListbyUnitId = async (unitId) => {
        try {
            const response = await Axios.get(`${BASE_URL}/getQrListbyUnitId/${unitId}`);
            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                setRooms(response.data);
            }
        } catch (error) {
            setErrors({ response: error.message });
        }
    };

    useEffect(() => {
        if (user && user.id) {
            if (user.role === 0 || user.role === 1) {
                getListbyUserId(user.id);
            } else if (user.role === 2) {
                getListbyUnitId(user.id_unitate);
            }
        }
    }, []);

    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="containerComp" data-aos="fade-in">
                    <h2>
                        <FormattedMessage id="dashboard.title" defaultMessage="Panou de control" />
                    </h2>
                    <h6>
                        <FormattedMessage id="dashboard.subtitle" defaultMessage="tot ce iti dorestii sa stii" />
                    </h6>
                </div>
                <div className="formContainer">
                    {rooms.length > 0 ? (
                        rooms.map((room, index) => (
                            <CardRoom
                                key={index}
                                number={room.qrCodeId}
                                roomName={room.roomName}
                                numarSesizari={room.numarSesizari}
                                hotelName={room.hotelName}
                            />
                        ))
                    ) : (
                        <div>
                            <div className="alert alert-danger" style={{ marginTop: "20px" }}>
                                <FormattedMessage id="dashboard.noRoomAdded" defaultMessage="Nicio camera adaugată." />
                            </div>
                            {(user && (user.role === 0 || user.role === 1)) && (
                                <Button
                                    variant="primary"
                                    href="/members/createQrCode"
                                    className="universalButton btn-rounded waves-effect"
                                >
                                    <FormattedMessage id="dashboard.addRoomButton" defaultMessage="Adaugă una." />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
                {errors.response && <div className="error">{errors.response}</div>}
            </div>
            <Footer />
        </div >
    );
};

export default Dashboard;

import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";

const GetHotelListByUserId = (props) => {
    const [hotelList, setHotelList] = useState([]);
    // eslint-disable-next-line
    const [errors, setErrors] = useState({});
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));

    const userData = {
        userId: user.id,
    };

    const getHotelList = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/getHotelListByUserIdUtils`, userData);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setHotelList(response.data);
        }
    };

    useEffect(() => {
        (async () => {
            getHotelList(userData);
        }
        )();
    }, []);

    const handleSelect = (e) => {
        const selectedHotel = e.target.value;
        props.onChangeHotel(selectedHotel);
    };

    return (
        <div className="buttonContainer" style={{ width: 300 }}>
            {hotelList.length === 1 ? (
                props.onChangeHotel(hotelList[0].idUnitate)
            ) : (
                <select className="form-control form-control-pay" id="hotelSelect" onChange={handleSelect} style={{ textAlign: "center" }} >
                    <option value={null}><FormattedMessage id="payedOption.selectHotel" defaultMessage="Alege hotelul" /></option>
                    {hotelList.map((hotel, index) => {
                        return (
                            <option key={index} value={hotel.idUnitate}>{hotel.denumire}</option>
                        );
                    })}
                </select>
            )}
        </div>
    );
};

export default GetHotelListByUserId;

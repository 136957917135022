import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

export const EditServiceButton = (props) => {
    const [service, setService] = useState(props.serviceName);
    const [departmentIdHotelId, setDepartmentIdHotelId] = useState(`${props.departmentId}-${props.hotelId}`);
    const [servicesList, setServicesList] = useState([]);
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    // eslint-disable-next-line no-unused-vars
    const [submitting, setSubmitting] = useState(false);

    const intl = useIntl();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const validateValues = (inputValues) => {
    //     let errors = {};
    //     if (!inputValues.departament) {
    //         errors.departament = intl.formatMessage({ id: 'addNewDep.error1', defaultMessage: 'Denumirea departamentului este obligatorie' });
    //     }
    //     if (!inputValues.hotelId) {
    //         errors.hotelId = intl.formatMessage({ id: 'addNewDep.error2', defaultMessage: 'Selecteaza hotelul' });
    //     }
    //     return errors;
    // }

    useEffect(() => {
        Axios.get(`${BASE_URL}/getServicesList/${user.id}`).then((response) => {
            setServicesList(response.data);
        });
    }, []);

    const handleEdit = async (e) => {
        e.preventDefault();
        const dataToSend = {
            serviceId: props.serviceId,
            service: service,
            departmentIdHotelId: departmentIdHotelId,
        };

        const response = await Axios.post(`${BASE_URL}/editService`, dataToSend);
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setSubmitting(true);
            handleClose();
            props.onSubmitedChanges(counter => counter + 1);
            return response;
        }
    };

    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <i className="bi bi-pencil-square"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="editServiciu.title"
                                defaultMessage={"Editeaza serviciul "}
                            />{props.serviceName}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup >
                            <InputGroup.Text style={{ width: 110 }}><FormattedMessage id="editServiciu.denumireServiciu" defaultMessage="Denumire" /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                onChange={(e) => { setService(e.target.value); }}
                                defaultValue={props.serviceName}
                                name="serviciu"
                            />
                            {errors.service && <p className="error">{errors.service}</p>}
                        </InputGroup>

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text style={{ width: 110 }}><FormattedMessage id="editServiciu.department" defaultMessage="Departament" /></InputGroup.Text>
                            <Form.Select
                                as="select"
                                onChange={(e) => { setDepartmentIdHotelId(e.target.value); }}
                            >
                                <option value="">{intl.formatMessage({ id: "editServiciu.selectDepartment", defaultMessage: "Selecteaza departamentul" })}</option>
                                {servicesList.map((val, key) => (
                                    <option key={key + 1} value={`${val.idDepartament}-${val.idUnitate}`} selected={props.departmentId && props.departmentId !== null && props.departmentId == val.idDepartament ? true : false}>{val.departName} - {val.hotelName}</option>
                                ))}
                            </Form.Select>
                            {errors.hotelId && <p className="error">{errors.hotelId}</p>}
                        </InputGroup>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} style={{ marginTop: 10, marginRight: 10 }}>
                            <FormattedMessage id="editServiciu.close" defaultMessage="Inchide" />
                        </Button>

                        <Button variant="success" type="submit" style={{ marginTop: 10 }}>
                            <FormattedMessage id="editServiciu.save" defaultMessage="Salveaza modificarile" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
import * as React from "react";

import { PieChart, pieArcLabelClasses } from "@mui/x-charts/PieChart";
import { useIntl, FormattedMessage } from "react-intl";

export default function PieChartWithCustomizedLabel({ selectedHotel }) {
  const [services, setServices] = React.useState([]);
  const [configData, setConfigData] = React.useState([]);
  const [subscriptionName, setSubscriptionName] = React.useState("");
  const [limitaSuperioaraState, setlimitaSuperioaraState] = React.useState("");
  const intl = useIntl();

  const getServicesByHotelId = async (hotelId) => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const response = await fetch(
      `${BASE_URL}/getServicesByIdHotel/${hotelId}`
    );
    const data = await response.json();
    return data;
  };

  const getConfigData = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const response = await fetch(`${BASE_URL}/getSubscriptionFromConfigTable`);
    const data = await response.json();
    return data;
  };

  React.useEffect(() => {
    if (selectedHotel) {
      getServicesByHotelId(selectedHotel).then((data) => {
        // console.log("data", data);
        setServices(data[0].numarServicii);
      });
      getConfigData().then((data) => {
        // console.log("config", data);
        setConfigData(data);
      });
    }
  }, [selectedHotel]);

  // console.log("services", services);
  // console.log("configData", configData);

  configData.map((item) => {
    const intervalArray = item.config_others.split("-");
    const limitaInferioara = parseInt(intervalArray[0], 10); // convertirea șirului în număr
    const limitaSuperioara = parseInt(intervalArray[1], 10);

    // console.log("Limita inferioară:", limitaInferioara);
    // console.log("Limita superioară:", limitaSuperioara);
    // console.log("config_name", item.config_name);
    // console.log("services", services);

    if (limitaInferioara !== 1000 && services > limitaInferioara && services < limitaSuperioara) {
      // console.log("OK1");
      if (subscriptionName !== item.config_name) {
        setSubscriptionName(item.config_name);
        setlimitaSuperioaraState(limitaSuperioara);
        // console.log("OK2");
      }
      // } else {
      //   if (subscriptionName !== item.config_name) {
      //     setSubscriptionName(item.config_name);
      //   }
    }

  });
  // console.log("subscriptionName", subscriptionName);
  // console.log("limitaSuperioaraState", limitaSuperioaraState);
  const left = limitaSuperioaraState - services;

  const data = [
    { label: intl.formatMessage({ id: "pie.ocupat", defaultMessage: "Ocupat" }), value: `${services}`, color: "#FF8042" },
    { label: intl.formatMessage({ id: "pie.liber", defaultMessage: "Liber" }), value: `${left}`, color: "#0088FE" },
  ];
  // console.log("data", data);
  const sizing = {
    margin: { right: 5, top: 50, left: 50, bottom: 50 },
    width: 400,
    height: 200,
    legend: { hidden: false },
  };
  // const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);
  // console.log("TOTAL", TOTAL);

  // const getArcLabel = (params) => {
  //   const percent = params.value / TOTAL;
  //   return `${(percent * 100).toFixed(0)}%`;
  // };
  const getArcLabel = (params) => {
    return `${params.value}`;
  };

  return (
    <div style={{}}>
      <div style={{ marginTop: "10px", justifyContent: "center" }}>
        <h4><FormattedMessage id="pieChart.package" defaultMessage="Pachetul tau este cel" /> <b>{subscriptionName}</b></h4>
        <h6>
          <FormattedMessage id="pieChart.services" defaultMessage="Ai " /> {services}
          <FormattedMessage id="pieChart.servicesOutOf" defaultMessage=" servicii ocupate din " /> {limitaSuperioaraState}
        </h6>
      </div>
      <div style={{ justifyContent: "center" }}>
        <PieChart
          series={[
            {
              data,
              innerRadius: 30,
              outerRadius: 120,
              paddingAngle: 3,
              cornerRadius: 5,
              startAngle: -90,
              endAngle: 90,
              cx: 150,
              cy: 150,
              arcLabel: getArcLabel,
            },
          ]}
          sx={{
            [`& .${pieArcLabelClasses.root}`]: {
              fill: "white",
              fontSize: 14,
            },
          }}
          {...sizing}
        />
      </div>
    </div>
  );
}
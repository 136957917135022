import React, { useContext } from "react";
import { UserContext } from "../../App";
import { useState } from "react";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import AddNewDepartament from "./addDepForm";
import GetDepartamentList from "./departamentsList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "navigation/index.css";
import "App.css";

const AddDepartament = () => {
    const [count, setCount] = useState(0);

    const { user } = useContext(UserContext);
    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    const handleState = () => {
        setCount(count + 1);
    };
    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <AddNewDepartament setCount={handleState} />
                    <hr />
                    <GetDepartamentList onChange={count} />
                </div>
            </div>
        </div>
    );
};

export default AddDepartament;
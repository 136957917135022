import React, { useContext } from "react";
import { UserContext } from "../../App";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import AddReviewForm from "./reviewsForm";
import ReviewsList from "./reviewsList";
import { FormattedMessage } from "react-intl";

import "./index.css";

const Reviews = () => {
  const [onChange, setOnChange] = React.useState(0);

  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstRevContainer">
        <div className="revContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="rev.mainTitle" defaultMessage="Pagina recenzii" />
          </h2>
          <h6>
            <FormattedMessage id="rev.subtitle" defaultMessage="adauga linkurile paginilor tale de socializare pentru a fi mai usor gasite de clientii tai" />
          </h6>
        </div>
      </div>
      <div className="containerRevForm">
        <AddReviewForm onChange={setOnChange} />
        <hr />
        <ReviewsList onChange={onChange} />
      </div>
      <Footer />
    </div>
  );
};

export default Reviews;
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getStorageUser } from "./admin/utils/getStorageUser";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import React, { useState, useEffect } from "react";

import HomePage from "homePage";
import Login from "auth/login/index";
import Register from "auth/register/index";
import Dashboard from "admin/dashboard/index";
import AddHotel from "admin/configUnit/index";
import AddDepartament from "admin/configDepartament/index";
import AddNewUser from "admin/configUsers/index";
import Services from "admin/configSevices/index";
import QrCode from "admin/qrList/index";
import ClientPage from "ui/index";
import PayedOptions from "admin/payedOptions/index";
import Wifi from "admin/wifiNetworks/index";
import Reviews from "admin/reviews/index";
import CheckInOut from "admin/checkInOut/index";
import Events from "admin/events/index";
import Restaurant from "admin/restaurant/index";
import Financial from "admin/financial/index";
import Success from "stripe/public/success";
import Cancel from "stripe/public/cancel";
import NotificationPermission from "./notifications/notifications";
export const UserContext = React.createContext();

const App = () => {
  const [user, setUser] = useState(() => getStorageUser());

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);
  // console.log("userContectApp", user);

  return (
    <div className="App">
      {/* <h1>Push Notifications Example</h1> */}
      <NotificationPermission />
      <BrowserRouter>
        <UserContext.Provider value={{ user, setUser }}>
          <Routes>
            <Route exact index element={<HomePage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/members/dashboard" element={<Dashboard />} />
            <Route path="/members/addNewHotel" element={<AddHotel />} />
            <Route path="/members/addNewDepartament" element={<AddDepartament />} />
            <Route path="/members/addNewUser" element={<AddNewUser />} />
            <Route path="/members/addServices" element={<Services />} />
            <Route path="/members/createQrCode" element={<QrCode />} />
            <Route path="/members/payedOptions" element={<PayedOptions />} />
            <Route path="/members/wifi" element={<Wifi />} />
            <Route path="/members/reviews" element={<Reviews />} />
            <Route path="/members/checkInOut" element={<CheckInOut />} />
            <Route path="/members/events" element={<Events />} />
            <Route path="/members/restaurant" element={<Restaurant />} />
            <Route path="/members/financial" element={<Financial />} />
            <Route path="/ui" element={<ClientPage />} />
            <Route path="/stripe/public/success" element={<Success />} />
            <Route path="/stripe/public/cancel" element={<Cancel />} />

          </Routes>
        </UserContext.Provider>
      </BrowserRouter>
    </div>
  );
};

export default App;

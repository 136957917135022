import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

export const EditDepButton = (props) => {
    const [departament, setDepartament] = useState(props.denumireDepartament);
    const [hotelId, setHotelId] = useState(props.hotelId);
    const [hotelList, setHotelList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const intl = useIntl();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // const validateValues = (inputValues) => {
    //     let errors = {};
    //     if (!inputValues.departament) {
    //         errors.departament = intl.formatMessage({ id: 'addNewDep.error1', defaultMessage: 'Denumirea departamentului este obligatorie' });
    //     }
    //     if (!inputValues.hotelId) {
    //         errors.hotelId = intl.formatMessage({ id: 'addNewDep.error2', defaultMessage: 'Selecteaza hotelul' });
    //     }
    //     return errors;
    // }

    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
            setHotelList(response.data);
        });
    }, []);

    const handleEdit = async () => {
        const dataToSend = {
            idDep: props.idDep,
            idUnitate: hotelId,
            departament: departament,
        };

        const response = await Axios.post(`${BASE_URL}/editDepartament`, dataToSend);
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setMessage(response.data.message);
            setSubmitting(true);
            handleClose();
            return response;
        }
    };

    useEffect(() => {
        if (submitting)
            props.onSubmitedChanges(counter => counter + 1);
        handleEdit();
    }, []);
    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
                id="editDepartment"
            >
                <i className="bi bi-pencil-square"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="editDep.title"
                                defaultMessage={"Editeaza departamentul "}
                            />{props.denumireDepartament}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <InputGroup >
                                <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="editDep.denumireDepartament" defaultMessage="Denumire" /></InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    onChange={(e) => { setDepartament(e.target.value); }}
                                    defaultValue={props.denumireDepartament}
                                    name="departament"
                                    id="denumireDepartamentEditModal"
                                />
                                {errors.departament && <p className="error">{errors.departament}</p>}
                            </InputGroup>

                            <InputGroup style={{ marginTop: 10 }}>
                                <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="editDep.hotel" defaultMessage="Hotel" /></InputGroup.Text>
                                <Form.Select
                                    as="select"
                                    onChange={(e) => { setHotelId(e.target.value); }}
                                    id="selectIdHotelEditModal"
                                >
                                    <option value="">{intl.formatMessage({ id: "editDep.selectHotel", defaultMessage: "Selecteaza hotelul" })}</option>
                                    {hotelList.map((val, key) => {
                                        return (
                                            <option key={key} value={val.idUnitate} selected={props.hotelId && props.hotelId !== null && props.hotelId == val.idUnitate ? true : false}>{val.denumire}</option>
                                        );
                                    })}
                                </Form.Select>
                                {errors.hotelId && <p className="error">{errors.hotelId}</p>}
                            </InputGroup>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} style={{ marginTop: 10, marginRight: 10 }}>
                            <FormattedMessage id="editDep.close" defaultMessage="Inchide" />
                        </Button>

                        <Button variant="success" type="submit" style={{ marginTop: 10 }} id="buttonEditModal">
                            <FormattedMessage id="editDep.save" defaultMessage="Salveaza modificarile" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
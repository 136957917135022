import React from "react";
import { FormattedMessage } from "react-intl";
import { useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Axios from "axios";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "../../navigation/index.css";
import "../../App.css";

const AddNewServiceForm = (props) => {
    const [inputFields, setinputFields] = useState({
        denumire: "",
        departamentHotel: ""
    });
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const [departamentsList, setDepartamentsList] = useState([]);

    const userData = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const nextStep = () => {
        navigate("/members/createQrCode");
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.denumire) {
            errors.denumire = <FormattedMessage id="addService.denumireError" defaultMessage="Adauga denumirea" />;
        }
        if (!inputValues.departamentHotel) {
            errors.departamentHotel = <FormattedMessage id="addService.departamentError" defaultMessage="Selecteaza departamentul" />;
        }
        return errors;
    };

    useEffect(() => {
        Axios.post(`${BASE_URL}/getDepartamentList`, { userId: userData.id }).then((response) => {
            setDepartamentsList(response.data);
        });
    }, []);

    // useEffect(() => {
    //     Axios.post(`${BASE_URL}/getHotelList`, { userId: userData.id }).then((response) => {
    //         setHotelList(response.data);
    //     });
    // }, []);

    const handleChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateValues(inputFields);
        setErrors(errors);
        // }
        // console.log({inputFields: inputFields});
        // const finishSubmit = async () => {
        if (Object.keys(errors).length === 0) {

            // const depId = inputFields.departament.split('-')[0];
            // const hotelId = inputFields.departament.split('-')[1];
            const data = {
                denumire: inputFields.denumire,
                idDepartamentIdHotel: inputFields.departamentHotel,
            };
            // console.log({data: data});
            const response = await Axios.post(`${BASE_URL}/addNewService`, data);
            // console.log({respNewUser: response});
            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                props.setSubmitedChanges(counter => counter + 1);
                return response;
            }
        }
    };

    // useEffect(() => {
    //     (async () => {
    //         finishSubmit();
    //     })();
    // }, []);

    // console.log({ departamentsList: departamentsList});

    return (
        <div className=" d-flex align-items-center justify-content-center">
            <div className="">
                <h1 className="text-center"><FormattedMessage id="addService.title" defaultMessage="Adauga serviciu nou" /></h1>
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addService.denumire" defaultMessage="Denumire" /></InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="denumire"
                            value={inputFields.denumire}
                            onChange={handleChange}
                            className="inputLabel"
                        />
                    </InputGroup>

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="addService.departament" defaultMessage="Departament" /></InputGroup.Text>
                        <Form.Control as="select" onChange={handleChange} name="departamentHotel" className="inputLabel">
                            <option value="0"><FormattedMessage id="addService.selectDep" defaultMessage="Selecteaza departamentul" /></option>
                            {departamentsList.map((val, key) => {
                                return (
                                    <option key={key} value={`${val.id}-${val.idUnitate}`}>{val.departName} - {val.unitName}</option>
                                );
                            })}
                        </Form.Control>
                    </InputGroup>

                    <div className="form-group text-center" style={{ paddingTop: 20 }}>
                        <button type="submit" className="universalButton buttonUniv btn-rounded waves-effect">
                            <FormattedMessage
                                id="addService.adauga_serviciu"
                                defaultMessage="Adauga serviciu"
                            />
                        </button>
                        <button className="universalButtonGreen buttonUnivGreen btn-rounded waves-effect" onClick={nextStep}>
                            <FormattedMessage
                                id="addService.pasul_urmator"
                                defaultMessage="Pasul urmator"
                            />
                        </button>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default AddNewServiceForm;
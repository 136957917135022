import React, { useEffect, useState } from "react";
import Axios from "axios";

import { FormattedMessage } from "react-intl";
import { DeleteWifiButton } from "./modal/delete";
import { EditWifiButton } from "./modal/edit";
import { Table } from "react-bootstrap";

const WifiList = () => {
    const [wifiList, setWifiList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    const [submitedChanges, setSubmitedChanges] = useState(0);

    const user = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const getWifiList = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/getWifiList`, userData);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setWifiList(response.data);
        }
    };
    const userData = {
        userId: user.id,
    };

    useEffect(() => {
        (async () => {
            getWifiList(userData);
        }
        )();
    }, [submitedChanges]);

    return (
        <div>
            <div className="" style={{ paddingTop: 20, paddingBottom: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="wifiList.title"
                        defaultMessage="Lista retelelor Wi-Fi"
                    />
                </h1>
            </div>
            <Table responsive style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}>
                <thead>
                    <tr>
                        <th>#</th>
                        <th><FormattedMessage id="wifiList.network" defaultMessage="Nume retea" /></th>
                        <th><FormattedMessage id="wifiList.security" defaultMessage="Securitate" /></th>
                        <th><FormattedMessage id="wifiList.password" defaultMessage="Parola" /></th>
                        <th><FormattedMessage id="wifiList.hotel" defaultMessage="Hotel" /></th>
                        <th><FormattedMessage id="wifiList.edit" defaultMessage="Editeaza" /></th>
                        <th><FormattedMessage id="wifiList.delete" defaultMessage="Sterge" /></th>
                    </tr>
                </thead>
                <tbody>
                    {wifiList.map((wifi, index) => {
                        return (
                            <tr key={wifi.idWifi}>
                                <td>{index + 1}</td>
                                <td>{wifi.network}</td>
                                <td>{wifi.security}</td>
                                <td>{wifi.password}</td>
                                <td>{wifi.hotel}</td>
                                <td>
                                    <EditWifiButton
                                        idWifi={wifi.idWifi}
                                        network={wifi.network}
                                        security={wifi.security}
                                        password={wifi.password}
                                        hotelName={wifi.hotel}
                                        idHotel={wifi.idHotel}
                                        onChanges={setSubmitedChanges}
                                    />
                                </td>
                                <td>
                                    <DeleteWifiButton
                                        idWifi={wifi.idWifi}
                                        network={wifi.network}
                                        hotelName={wifi.hotel}
                                        onChanges={setSubmitedChanges}
                                    />
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default WifiList;
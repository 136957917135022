import React, { useContext } from "react";
import { UserContext } from "../../App";

import AddWifiForm from "./wifiForm";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import WifiList from "./wifiList";
import { FormattedMessage } from "react-intl";
import "./index.css";

const Wifi = () => {
  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstWifiContainer">
        <div className="wifiContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="wifi.mainTitle" defaultMessage="Pagina WiFi" />
          </h2>
          <h6>
            <FormattedMessage id="wifi.subtitle" defaultMessage="adauga o retea noua de wifi" />
          </h6>
        </div>
      </div>
      <div className="containerWifiNetworksForm">
        <AddWifiForm />
        <hr />
        <WifiList />
      </div>
      <Footer />
    </div>
  );
};

export default Wifi;
import React from "react";
import TopMenu from "navigation/topMenu";
import Menu from "../../navigation/menu";
import RegisterComponent from "./registerComp";

const Register = () => {
    return (
        <div>
            <TopMenu />
            <Menu />
            <RegisterComponent />
        </div>
    );
};

export default Register;
import React, { useContext } from "react";
import { UserContext } from "../../App";
import { useState } from "react";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import AddNewHotel from "./addNewHotel";
import GetHotelList from "./hotelList";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import "navigation/index.css";
import "../../App.css";

const AddHotel = () => {
    const { user } = useContext(UserContext);
    const [count, setCount] = useState(0);
    console.log("userContextAddHotel", user);

    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    const handleState = () => {
        setCount(count + 1);
    };
    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="container" data-aos="fade-in">
                    <AddNewHotel setCount={handleState} />
                    <hr />
                    <GetHotelList onChange={count} />
                </div>
            </div>
        </div>
    );
};

export default AddHotel;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button, Modal, Form, InputGroup } from "react-bootstrap";

export const EditUserButton = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [hotelList, setHotelList] = useState([]);
    const [departamentsList, setDepartamentsList] = useState([]);
    const [inputFields, setinputFields] = useState({
        userId: props.userId ? props.userId : "",
        nume: props.nume ? props.nume : "",
        prenume: props.prenume ? props.prenume : "",
        email: props.user ? props.user : "",
        idHotel: props.idHotel ? props.idHotel : "",
        idDepartament: props.idDepartament ? props.idDepartament : "",
        role: props.role ? props.role : "",
    });

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const userData = JSON.parse(localStorage.getItem("user"));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const intl = useIntl();

    const handleInputChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const validate = (values) => {
        const errors = {};
        if (!values.nume) {
            errors.nume = intl.formatMessage({ id: "configUsers.modal.edit.errors.name", defaultMessage: "Numele este obligatoriu" });
        }
        if (!values.prenume) {
            errors.prenume = intl.formatMessage({ id: "configUsers.modal.edit.errors.surname", defaultMessage: "Prenumele este obligatoriu" });
        }
        if (!values.email) {
            errors.email = intl.formatMessage({ id: "configUsers.modal.edit.errors.email", defaultMessage: "Email-ul este obligatoriu" });
        }
        if (!values.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            errors.email = intl.formatMessage({ id: "configUsers.modal.edit.errors.emailInvalid", defaultMessage: "Adresa de email nu este valida" });
        }

        if (!values.role) {
            errors.role = intl.formatMessage({ id: "configUsers.modal.edit.errors.roles" });
        }
        return errors;
    };

    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: userData.id }).then((response) => {
            setHotelList(response.data);
        });
    }, []);

    useEffect(() => {
        Axios.post(`${BASE_URL}/getDepartamentList`, { userId: userData.id }).then((response) => {
            setDepartamentsList(response.data);
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const validationErrors = validate(inputFields);
        setErrors(validationErrors);
        if (Object.keys(validationErrors).length === 0) {
            const response = await Axios.post(`${BASE_URL}/editUser`, {
                userId: props.userId,
                nume: inputFields.nume,
                prenume: inputFields.prenume,
                email: inputFields.email,
                idHotel: inputFields.idHotel,
                idDepartament: inputFields.idDepartament,
                role: inputFields.role,
            });
            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                props.onChange(counter => counter + 1);
                handleClose();
                return response;
            }
        }
    };
    return (
        <>
            <Button variant="primary" onClick={handleShow}>
                <i className="bi bi-pencil-square"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="configUsers.modal.edit.title"
                                defaultMessage={"Editeaza utilizatorul"}
                            /> {props.email}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup>
                            <InputGroup.Text id="basic-addon1" style={{ width: 110 }}><FormattedMessage id="configUsers.modal.edit.prenume" defaultMessage="Prenume" /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="prenume"
                                defaultValue={props.prenume}
                                onChange={handleInputChange}
                            />
                            {errors.prenume && <p className="error">{errors.prenume}</p>}
                        </InputGroup>

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text id="basic-addon2" style={{ width: 110 }}><FormattedMessage id="configUsers.modal.edit.nume" defaultMessage="Nume" /></InputGroup.Text>
                            <Form.Control
                                type="text"
                                name="nume"
                                defaultValue={props.nume}
                                onChange={handleInputChange}
                            />
                            {errors.nume && <p className="error">{errors.nume}</p>}
                        </InputGroup >

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text id="basic-addon3" style={{ width: 110 }}> <FormattedMessage id="configUsers.modal.edit.email" defaultMessage="Email" /> </InputGroup.Text>
                            <Form.Control
                                type="email"
                                name="email"
                                defaultValue={props.user}
                                onChange={handleInputChange}
                            />
                            {errors.email && <p className="error">{errors.email}</p>}
                        </InputGroup>

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text id="basic-addon4" style={{ width: 110 }}> <FormattedMessage id="configUsers.modal.edit.hotel" defaultMessage="Hotel" /> </InputGroup.Text>
                            <Form.Control as="select" onChange={handleInputChange} name="idHotel">
                                <option value="0"><FormattedMessage id="configUsers.modal.edit.selectHotel" defaultMessage="Selecteaza hotelul" /></option>
                                {hotelList.map((val, key) => {
                                    return (
                                        <option key={key} value={`${val.idUnitate}`} selected={props.idHotel === val.idUnitate}>{val.denumire}</option>
                                    );
                                })}
                            </Form.Control>
                            {errors.hotel && <p className="error">{errors.hotel}</p>}
                        </InputGroup>

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text id="basic-addon5" style={{ width: 110 }}> <FormattedMessage id="configUsers.modal.edit.departament" defaultMessage="Departament" /> </InputGroup.Text>
                            <Form.Control as="select" onChange={handleInputChange} name="idDepartament">
                                <option value="0"><FormattedMessage id="configUsers.modal.edit.selectDep" defaultMessage="Selecteaza departamentul" /></option>
                                {departamentsList.map((val, key) => {
                                    return (
                                        <option key={key} value={`${val.id}`} selected={props.idDepartament === val.id}>{val.departName} - {val.unitName}</option>
                                    );
                                })}
                            </Form.Control>
                            {errors.departament && <p className="error">{errors.departament}</p>}
                        </InputGroup>

                        <InputGroup style={{ marginTop: 10 }}>
                            <InputGroup.Text id="basic-addon6" style={{ width: 110 }}> <FormattedMessage id="configUsers.modal.edit.role" defaultMessage="Rol" /> </InputGroup.Text>
                            <Form.Control as="select" onChange={handleInputChange} name="role" >
                                <option value="0"><FormattedMessage id="configUsers.modal.edit.selectRole" defaultMessage="Selecteaza rolul" /></option>
                                {props.role === 1 ? <option value="1" selected><FormattedMessage id="configUsers.modal.edit.manager" defaultMessage="Manager" /></option>
                                    : <option value="1"><FormattedMessage id="configUsers.modal.edit.manager" defaultMessage="Manager" /></option>}
                                {props.role === 2 ? <option value="2" selected><FormattedMessage id="configUsers.modal.edit.angajat" defaultMessage="Angajat" /></option>
                                    : <option value="2"><FormattedMessage id="configUsers.modal.edit.angajat" defaultMessage="Angajat" /></option>}
                            </Form.Control>
                            {errors.role && <p className="error">{errors.role}</p>}
                        </InputGroup>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose} style={{ marginTop: 10, marginRight: 10 }}>
                            <FormattedMessage id="configUsers.modal.edit.close" defaultMessage="Inchide" />
                        </Button>

                        <Button variant="success" type="submit" style={{ marginTop: 10 }}>
                            <FormattedMessage id="configUsers.modal.edit.submit" defaultMessage="Salveaza modificarile" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage, useIntl } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Button, Modal, Form } from "react-bootstrap";

export const EditUnitButton = (props) => {
    const [show, setShow] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitting, setSubmitting] = useState(false);
    const [inputFields, setinputFields] = useState({
        unitName: "",
        unitAddress: "",
        unitCity: "",
        unitCounty: "",
        unitPhone: "",
        unitEmail: "",
        unitCheckIn: "",
        unitCheckOut: "",
        unitBreakfast: "",
        unitLunch: "",
        unitDinner: "",
        unitCompany: "",
        unitCUI: "",
        unitNRC: "",
        unitCompanyAddress: "",
        unitCompanyPhone: "",
        unitCompanyEmail: "",
        unitCompanyBankAccount: "",
        unitCompanyBank: "",
        unitCompanyLegalRepresentative: "",
    });

    const intl = useIntl();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const validateValues = (inputValues) => {
        const errors = {};
        if (inputValues.unitName.length === 0) {
            errors.unitName = intl.formatMessage({
                id: "editList.unitNameError",
                defaultMessage: "Introdu descriere hotelului"
            });
        }
        if (!inputValues.unitAddress) {
            errors.unitAddress = intl.formatMessage({
                id: "editList.unitAddressError",
                defaultMessage: "Introdu adresa hotelului"
            });
        }
        if (inputValues.unitCity.length === 0) {
            errors.unitCity = intl.formatMessage({
                id: "editList.unitCityError",
                defaultMessage: "Introdu localitatea hotelului"
            });
        }
        if (!inputValues.unitCounty) {
            errors.unitCounty = intl.formatMessage({
                id: "editList.unitCountyError",
                defaultMessage: "Introdu judetul hotelului"
            });
        }
        if (!inputValues.unitPhone) {
            errors.unitPhone = intl.formatMessage({
                id: "editList.unitPhoneError",
                defaultMessage: "Introdu telefonul hotelului"
            });
        }
        if (!inputValues.unitEmail) {
            errors.unitEmail = intl.formatMessage({
                id: "editList.unitEmailError",
                defaultMessage: "Introdu emailul hotelului"
            });
        }
        if (!inputValues.unitCheckIn) {
            errors.unitCheckIn = intl.formatMessage({
                id: "editList.unitCheckInError",
                defaultMessage: "Introdu ora de check-in a hotelului"
            });
        }
        if (!inputValues.unitCheckOut) {
            errors.unitCheckOut = intl.formatMessage({
                id: "editList.unitCheckOutError",
                defaultMessage: "Introdu ora de check-out a hotelului"
            });
        }
        if (!inputValues.unitCompany) {
            errors.unitCompany = intl.formatMessage({
                id: "editList.unitCompanyError",
                defaultMessage: "Introdu societatea"
            });
        }
        if (!inputValues.unitCUI) {
            errors.unitCUI = intl.formatMessage({
                id: "editList.unitCUIError",
                defaultMessage: "Introdu CUI"
            });
        }
        if (!inputValues.unitNRC) {
            errors.unitNRC = intl.formatMessage({
                id: "editList.unitNRCError",
                defaultMessage: "Introdu NRC"
            });
        }
        if (!inputValues.unitCompanyAddress) {
            errors.unitCompanyAddress = intl.formatMessage({
                id: "editList.unitCompanyAddressError",
                defaultMessage: "Introdu adresa societatii"
            });
        }
        if (!inputValues.unitCompanyPhone) {
            errors.unitCompanyPhone = intl.formatMessage({
                id: "editList.unitCompanyPhoneError",
                defaultMessage: "Introdu telefonul societatii"
            });
        }
        if (!inputValues.unitCompanyEmail) {
            errors.unitCompanyEmail = intl.formatMessage({
                id: "editList.unitCompanyEmailError",
                defaultMessage: "Introdu emailul societatii"
            });
        }
        if (!inputValues.unitCompanyBankAccount) {
            errors.unitCompanyBankAccount = intl.formatMessage({
                id: "editList.unitCompanyBankAccountError",
                defaultMessage: "Introdu contul bancar al societatii"
            });
        }
        if (!inputValues.unitCompanyBank) {
            errors.unitCompanyBank = intl.formatMessage({
                id: "editList.unitCompanyBankError",
                defaultMessage: "Introdu banca societatii"
            });
        }
        if (!inputValues.unitCompanyLegalRepresentative) {
            errors.unitCompanyLegalRepresentative = intl.formatMessage({
                id: "editList.unitCompanyLegalRepresentativeError",
                defaultMessage: "Introdu reprezentantul legal al societatii"
            });
        }
        return errors;
    };

    const handleChange = (e) => {
        setinputFields({
            ...inputFields,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setErrors(validateValues(inputFields));
        setSubmitting(true);
    };

    const finishSubmit = async () => {

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            const unitData = {
                hotelId: props.idUnitate,
                hotelName: inputFields.unitName,
                hotelAddress: inputFields.unitAddress,
                hotelCity: inputFields.unitCity,
                hotelCounty: inputFields.unitCounty,
                hotelPhone: inputFields.unitPhone,
                hotelEmail: inputFields.unitEmail,
                hotelCheckIn: inputFields.unitCheckIn,
                hotelCheckOut: inputFields.unitCheckOut,
                hotelBreakfast: inputFields.unitBreakfast,
                hotelLunch: inputFields.unitLunch,
                hotelDinner: inputFields.unitDinner,
                hotelCompany: inputFields.unitCompany,
                hotelCUI: inputFields.unitCUI,
                hotelNRC: inputFields.unitNRC,
                hotelCompanyAddress: inputFields.unitCompanyAddress,
                hotelCompanyPhone: inputFields.unitCompanyPhone,
                hotelCompanyEmail: inputFields.unitCompanyEmail,
                hotelCompanyBankAccount: inputFields.unitCompanyBankAccount,
                hotelCompanyBank: inputFields.unitCompanyBank,
                hotelCompanyLegalRepresentative: inputFields.unitCompanyLegalRepresentative,
            };

            const BASE_URL = process.env.REACT_APP_BASE_URL;
            const response = await Axios.post(`${BASE_URL}/editHotel`, unitData);

            if (response.data.error) {
                setErrors({ response: response.data.error });
            } else {
                handleClose();
                return response;
            }
        }
    };

    useEffect(() => {

        if (submitting) {
            props.onSubmitedChanges(counter => counter + 1);
            finishSubmit();
        }
    }, [submitting]);

    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}>
                <i className="bi bi-pencil-square"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage
                                id="editList.edit"
                                defaultMessage="Editeaza "
                            />
                            {props.denumire}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group controlId="unitName">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitName"
                                    defaultMessage="Introdu descriere hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitName = inputFields.unitName ? inputFields.unitName : props.denumire} name="unitName" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitName ? (
                            <p className="error">{errors.unitName}</p>
                        ) : null}

                        <Form.Group controlId="unitAddress">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitAddress"
                                    defaultMessage="Introdu adresa hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitAddress = inputFields.unitAddress ? inputFields.unitAddress : props.adresa} name="unitAddress" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitAddress ? (
                            <p className="error">{errors.unitAddress}</p>
                        ) : null}

                        <Form.Group controlId="unitCity">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCity"
                                    defaultMessage="Introdu localitatea hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCity = inputFields.unitCity ? inputFields.unitCity : props.localitatea} name="unitCity" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCity ? (
                            <p className="error">{errors.unitCity}</p>
                        ) : null}

                        <Form.Group controlId="unitCounty">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCounty"
                                    defaultMessage="Judetul hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCounty = inputFields.unitCounty ? inputFields.unitCounty : props.judetul} name="unitCounty" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCounty ? (
                            <p className="error">{errors.unitCounty}</p>
                        ) : null}

                        <Form.Group controlId="unitPhone">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitPhone"
                                    defaultMessage="Telefonul hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitPhone = inputFields.unitPhone ? inputFields.unitPhone : props.telefon} name="unitPhone" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitPhone ? (
                            <p className="error">{errors.unitPhone}</p>
                        ) : null}

                        <Form.Group controlId="unitEmail">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitEmail"
                                    defaultMessage="Unit Email"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitEmail = inputFields.unitEmail ? inputFields.unitEmail : props.email} name="unitEmail" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitEmail ? (
                            <p className="error">{errors.unitEmail}</p>
                        ) : null}

                        <Form.Group controlId="unitCheckIn">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCheckIn"
                                    defaultMessage="Ora de check-in a hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCheckIn = inputFields.unitCheckIn ? inputFields.unitCheckIn : props.checkIn} name="unitCheckIn" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCheckIn ? (
                            <p className="error">{errors.unitCheckIn}</p>
                        ) : null}

                        <Form.Group controlId="unitCheckOut">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCheckOut"
                                    defaultMessage="Ora de check-out a hotelului"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCheckOut = inputFields.unitCheckOut ? inputFields.unitCheckOut : props.checkOut} name="unitCheckOut" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCheckOut ? (
                            <p className="error">{errors.unitCheckOut}</p>
                        ) : null}

                        <Form.Group controlId="unitBreakfast">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitBreakfast"
                                    defaultMessage="Program pentru masa de mic dejun"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitBreakfast = inputFields.unitBreakfast ? inputFields.unitBreakfast : props.breakfast} name="unitBreakfast" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitBreakfast ? (
                            <p className="error">{errors.unitBreakfast}</p>
                        ) : null}

                        <Form.Group controlId="unitLunch">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitLunch"
                                    defaultMessage="Program pentru masa de pranz"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitLunch = inputFields.unitLunch ? inputFields.unitLunch : props.lunch} name="unitLunch" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitLunch ? (
                            <p className="error">{errors.unitLunch}</p>
                        ) : null}

                        <Form.Group controlId="unitDinner">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitDinner"
                                    defaultMessage="Program pentru masa de seara"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitDinner = inputFields.unitDinner ? inputFields.unitDinner : props.dinner} name="unitDinner" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitDinner ? (
                            <p className="error">{errors.unitDinner}</p>
                        ) : null}

                        <Form.Group controlId="unitCompany">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompany"
                                    defaultMessage="Societate"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCompany = inputFields.unitCompany ? inputFields.unitCompany : props.societate} name="unitCompany" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCompany ? (
                            <p className="error">{errors.unitCompany}</p>
                        ) : null}

                        <Form.Group controlId="unitCUI">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCUI"
                                    defaultMessage="CUI"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCUI = inputFields.unitCUI ? inputFields.unitCUI : props.cui} name="unitCUI" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCUI ? (
                            <p className="error">{errors.unitCUI}</p>
                        ) : null}

                        <Form.Group controlId="unitNRC">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitNRC"
                                    defaultMessage="NRC"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitNRC = inputFields.unitNRC ? inputFields.unitNRC : props.nrc} name="unitNRC" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitNRC ? (
                            <p className="error">{errors.unitNRC}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyAddress">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyAddress"
                                    defaultMessage="Adresa societatii"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCompanyAddress = inputFields.unitCompanyAddress ? inputFields.unitCompanyAddress : props.adresaSocietate} name="unitCompanyAddress" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCompanyAddress ? (
                            <p className="error">{errors.unitCompanyAddress}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyPhone">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyPhone"
                                    defaultMessage="Telefon societatii"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCompanyPhone = inputFields.unitCompanyPhone ? inputFields.unitCompanyPhone : props.telefonSocietate} name="unitCompanyPhone" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCompanyPhone ? (
                            <p className="error">{errors.unitCompanyPhone}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyEmail">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyEmail"
                                    defaultMessage="Email societatii"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCompanyEmail = inputFields.unitCompanyEmail ? inputFields.unitCompanyEmail : props.emailSocietate} name="unitCompanyEmail" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCompanyEmail ? (
                            <p className="error">{errors.unitCompanyEmail}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyBankAccount">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyBankAccount"
                                    defaultMessage="Cont bancar societatii"
                                />
                            </Form.Label>
                            <Form.Control type="text" defaultValue={inputFields.unitCompanyBankAccount = inputFields.unitCompanyBankAccount ? inputFields.unitCompanyBankAccount : props.contBancar} name="unitCompanyBankAccount" onChange={handleChange} />
                        </Form.Group>
                        {errors.unitCompanyBankAccount ? (
                            <p className="error">{errors.unitCompanyBankAccount}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyBank">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyBank"
                                    defaultMessage="Banca societatii"
                                />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={inputFields.unitCompanyBank = inputFields.unitCompanyBank ? inputFields.unitCompanyBank : props.banca}
                                name="unitCompanyBank"
                                onChange={handleChange}
                                style={{ border: errors.unitCompanyBank ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.unitCompanyBank ? (
                            <p className="error">{errors.unitCompanyBank}</p>
                        ) : null}

                        <Form.Group controlId="unitCompanyLegalRepresentative">
                            <Form.Label>
                                <FormattedMessage
                                    id="editList.unitCompanyLegalRepresentative"
                                    defaultMessage="Reprezentant legal"
                                />
                            </Form.Label>
                            <Form.Control
                                type="text"
                                defaultValue={inputFields.unitCompanyLegalRepresentative = inputFields.unitCompanyLegalRepresentative ? inputFields.unitCompanyLegalRepresentative : props.reprezentantLegal}
                                name="unitCompanyLegalRepresentative"
                                autoFocus
                                onChange={handleChange}
                                style={{ border: errors.unitCompanyLegalRepresentative ? "1px solid red" : null }}
                            />
                        </Form.Group>
                        {errors.unitCompanyLegalRepresentative ? (
                            <p className="error">{errors.unitCompanyLegalRepresentative}</p>
                        ) : null}
                        <br />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="success" type="submit" >
                            <FormattedMessage
                                id="editList.submit"
                                defaultMessage="Salveaza modificarile"
                            />

                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};

export default EditUnitButton;

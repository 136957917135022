import React from "react";
import { Navbar, Nav } from "react-bootstrap";

const FooterMenu = () => {
  return (
    <>
      <Navbar
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          marginTop: 20,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#313031",
          zIndex: 1000 // Optional, for ensuring it stays on top of other elements
        }}
      >
        <Nav className="mr-auto">
          <Nav.Link href="/terms">Termeni si conditii</Nav.Link>
          <Nav.Link href="/privacy">Politica de confidentialitate</Nav.Link>
        </Nav>
      </Navbar>
    </>
  );
};

export default FooterMenu;
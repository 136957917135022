import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../App";
import { FormattedMessage } from "react-intl";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";

import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import PayedOptionsForm from "admin/payedOptions/payedOptionsForm";
import HotelSelectComp from "admin/payedOptions/hotelSelectComp";
import Footer from "navigation/footer";

import "./index.css";

const PayedOptions = () => {
    // eslint-disable-next-line
    const [errors, setErrors] = useState({});
    // const [isLoggedIn, setLoginStatus] = useState(false);
    const [selectedHotel, setSelectedHotel] = useState(null);
    const [payedOptions, setPayedOptions] = useState([]);
    const [payedOptionsConfigTable, setPayedOptionsConfigTable] = useState([]);
    const [hotelCount, setHotelCount] = useState(undefined);

    const { user } = useContext(UserContext);
    if (!user) {
        // Redirect to login if no user
        window.location.href = "/login";
        return null;
    }

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    // const user = JSON.parse(localStorage.getItem("user"));

    const getHotelCount = async () => {
        const response = await Axios.post(`${BASE_URL}/getHotelList`, {
            userId: user.id,
        });
        let hotelListCount = undefined;
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            hotelListCount = response.data.length;
            if (hotelListCount === 1) {
                setSelectedHotel(response.data[0].idUnitate);
            }
        }
        return hotelListCount;
    };

    const handleSelect = (selectedHotel) => {
        setSelectedHotel(selectedHotel);
    };

    // scoatem valorile din BD -> payed_options pt fiecare hotel in parte
    const getPayedOptionsFromDatabase = async (idUnitate) => {
        const response = await Axios.post(`${BASE_URL}/getPayedOptionsFromDatabase`, { idUnitate });
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            return response.data;
        }
    };

    const getPayedOptionsFromConfigTable = async (type) => {
        const response = await Axios.post(`${BASE_URL}/getPayedOptionsFromConfigTable`, { type });
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            return response.data;
        }
    };

    const updatePayedOptionsFromConfigTable = async () => {
        const payedOptionsFromConfigTable = await getPayedOptionsFromConfigTable("payedOption");
        setPayedOptionsConfigTable(payedOptionsFromConfigTable);
    };

    const updateHotelCount = async () => {
        const userData = { userId: user.id };
        const hotelListCount = await getHotelCount(userData);
        setHotelCount(hotelListCount);
    };

    useEffect(() => {
        updateHotelCount();
        updatePayedOptionsFromConfigTable();
    }, []);

    const getPayedOptions = async () => {
        const payedOptionsResponse = await getPayedOptionsFromDatabase(selectedHotel);
        setPayedOptions(payedOptionsResponse);
    };

    useEffect(() => {
        getPayedOptions();
    }, [selectedHotel]);

    if (typeof hotelCount === "undefined") {
        return "Loading...";
    }
    return (
        <div>
            <TopMenu />
            <Menu />
            <div className="firstContainer">
                <div className="containerPayedOption" data-aos="fade-in">
                    <h1><FormattedMessage id="payedOption.title" defaultMessage="Optiuni cu plata" /></h1>
                    {hotelCount > 1 && (
                        <>
                            <HotelSelectComp onChangeHotel={handleSelect} />

                            {selectedHotel !== null &&
                                <PayedOptionsForm
                                    key={selectedHotel}
                                    payedOptions={payedOptions}
                                    selectedHotel={selectedHotel}
                                    hotelCount={hotelCount}
                                    payedOptionsConfigTable={payedOptionsConfigTable}
                                />
                            }
                        </>
                    )}
                    {hotelCount === 1 && (
                        <>
                            <PayedOptionsForm
                                key={selectedHotel}
                                payedOptions={payedOptions}
                                selectedHotel={selectedHotel}
                                hotelCount={hotelCount}
                                payedOptionsConfigTable={payedOptionsConfigTable}
                            />
                        </>
                    )}

                </div>
            </div>
            <Footer />
        </div>
    );
};

export default PayedOptions;
import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

import "bootstrap-icons/font/bootstrap-icons.css";

export const DeleteServiceButton = (props) => {
    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (p) => {
        p.preventDefault();
        setSubmitting(true);
    };

    const finishSubmit = async () => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const data = {
            serviciuId: props.serviciuId,
        };
        await Axios.post(`${BASE_URL}/deleteService`, data).then(() => {
        });
    };

    useEffect(() => {
        if (submitting) {
            props.onSubmitedChanges(counter => counter + 1);
            finishSubmit();
        }
    }, [submitting]);

    return (
        <>
            <Button
                variant="danger"
                onClick={handleShow}
            >
                <i className="bi bi-trash3"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <FormattedMessage id="servicesDel.delMess" defaultMessage="Stergi serviciul " />{props.serviceName} <br />
                            <FormattedMessage id="servicesDel.of" defaultMessage=" al " /> {props.denumireUnitate} ?
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FormattedMessage id="servicesDel.no" defaultMessage="Nu" />
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            <FormattedMessage id="servicesDel.yes" defaultMessage="Da" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
import React, { useContext } from "react";
import { Context } from "../utils/intl/wrapper";
import "./index.css";
// import ro from "../images/lang/ro.png";
// import en from "../images/lang/uk.png";

const TopMenu = () => {
    const context = useContext(Context);
    return (
        <div>
            <section id="topbar" className="d-flex align-items-center">
                <div className="container d-flex justify-content-center justify-content-md-between">
                    <div className="contact-info d-flex align-items-center">
                        <i className="bi bi-envelope d-flex align-items-center"><a href="mailto:contact@example.com">contact@qralert.ro</a></i>
                        <i className="bi bi-phone d-flex align-items-center ms-4"><span>+40 734 147 407</span></i>
                    </div>
                    <select value={context?.locale} onChange={context?.selectLanguage}>
                        <option value="ro">ro</option>
                        <option value="en">en</option>
                    </select>
                </div>
            </section>

        </div>
    );
};

export default TopMenu;
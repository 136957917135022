import React, { useEffect, useState } from "react";
import Axios from "axios";

import { useIntl, FormattedMessage } from "react-intl";
import { Table } from "react-bootstrap";

import PaymentButton from "../../stripe/checkOut";
import Invoice from "./downloadInvoiceButton";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { dateParser } from "../utils/dataParser";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";

const stripePromise = loadStripe("pk_test_51LKcfrJuzB7ZQD2MZACzOaBIiZHzcOq759Oh0LUSXRBbTjF6AlvxVnSL9QqhEsWKzC0q4KggAfTkcay3tixDog4b005YiioLtk");

const options = {
  mode: "payment",
  amount: 20000,
  currency: "ron",
};

const GetInvoices = (props) => {

  // eslint-disable-next-line
  const [invoices, setInvoices] = useState([]);
  // eslint-disable-next-line
  const [errors, setErrors] = useState({});
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const intl = useIntl();

  const getFinancialPlan = async (selectedHotel) => {
    const response = await Axios.get(`${BASE_URL}/getInvoices/${selectedHotel}`);
    if (response.data.error) {
      setErrors({ response: response.data.error });
    } else {

      if (response.data !== invoices)
        setInvoices(response.data);
    }
  };

  useEffect(() => {
    (async () => {
      if (props.selectedHotel !== null) {
        getFinancialPlan(props.selectedHotel);
      }
    })();
  }, [props.selectedHotel]);

  // console.log(invoices);

  return (
    <div>
      {invoices.length === 0 ? (
        <div>
          {props.selectedHotel === null ? (
            <div className="noInvoices">
              <FormattedMessage
                id="invoice.selectHotel"
                defaultMessage="Selectati mai intai un hotel"
              />
            </div>
          ) : (
            <div className="noInvoices">
              <FormattedMessage
                id="invoice.noInvoices"
                defaultMessage="Nu aveti facturi"
              />
            </div>)
          }
        </div>
      ) : (
        <>
          <div className="" style={{ paddingTop: 20, paddingBottom: 20 }} >
            <h1 className="text-center">
              <FormattedMessage
                id="invoice.title"
                defaultMessage="Facturile mele"
              />
            </h1>
          </div>
          <Table responsive style={{ paddingTop: 20, maxWidth: 700, marginLeft: "auto", marginRight: "auto" }}>
            <thead>
              <tr>
                <th>#</th>
                <th><FormattedMessage id="invoice.price" defaultMessage="Pret" /></th>
                <th><FormattedMessage id="invoice.period" defaultMessage="Perioada" /></th>
                <th><FormattedMessage id="invoice.state" defaultMessage="Stare" /></th>
                <th><FormattedMessage id="invoice.invoiceDate" defaultMessage="Data facturarii" /></th>
                <th><FormattedMessage id="invoice.paymentDate" defaultMessage="Data platii" /></th>
                <th><FormattedMessage id="invoice.downloadInvoice" defaultMessage="Descarca" /></th>
              </tr>
            </thead>
            <tbody>
              {invoices.map((val, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{val.price}ron</td>
                  <td>{val.invoicePeriod}</td>
                  <td>{val.payment_state === 0 ? (intl.formatMessage({ id: "invoice.neplatita", defaultMessage: "neplatita" })) : (intl.formatMessage({ id: "invoice.platita", defaultMessage: "platita" }))}</td>
                  <td>{dateParser(val.createdAt)}</td>
                  <td>
                    {val.payment_date !== null ?
                      dateParser(val.payment_date) :
                      (
                        <Elements
                          stripe={stripePromise}
                          options={options}
                        >
                          <PaymentButton
                            invoiceId={val.id}
                            myPrice={parseInt(val.price)}
                            productName={"qrAlert"}
                          />
                        </Elements>
                      )
                    }
                  </td>
                  <td>
                    <Invoice
                      invoiceId={val.invoiceId}
                      invoiceType={val.invoiceType}
                      createdAt={dateParser(val.createdAt)}
                      societate={val.societate}
                      adresa_societate={val.adresa_societate}
                      telefon_societate={val.telefon_societate}
                      email_societate={val.email_societate}
                      cui={val.cui}
                      nrc={val.nrc}
                      invoicePeriod={val.invoicePeriod}
                      price={val.price}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

export default GetInvoices;
import React, { useState, useContext } from "react";
import { UserContext } from "../../App";

import CheckInOutForm from "./checkInOutForm";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import Footer from "navigation/footer";
import CheckInOutList from "./checkInOutList";
import { FormattedMessage } from "react-intl";
import "./index.css";

const CheckInOut = () => {
  const [count, setCount] = useState(0);

  const { user } = useContext(UserContext);
  if (!user) {
    // Redirect to login if no user
    window.location.href = "/login";
    return null;
  }

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstCheckInOutContainer">
        <div className="checkInOutContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="checkInOut.mainTitle" defaultMessage="Pagina Check-In/Out" />
          </h2>
          <h6>
            <FormattedMessage id="checkInOut.subtitle" defaultMessage="adauga orele de check-in si check-out" />
          </h6>
        </div>
      </div>
      <div className="containerCheckInOutForm">
        <CheckInOutForm setSubmitedChanges={setCount} />
        <hr />
        <CheckInOutList onChange={count} />
      </div>
      <Footer />
    </div>
  );
};

export default CheckInOut;
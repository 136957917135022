import React, { useState } from "react";
import { useIntl } from "react-intl";

import "./public/index.css";

const PaymentButton = (props) => {
  const [loading, setLoading] = useState(false);
  const intl = useIntl();

  const handlePayment = async () => {
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    try {
      setLoading(true);

      const response = await fetch(`${BASE_URL}/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ invoiceId: props.invoiceId, productName: props.productName, myPrice: props.myPrice * 100 }),
      });
      const responseData = await response.json();

      if (responseData.status === "success") {
        // Redirect către pagina de plată a Stripe
        window.location.href = responseData.checkout_url;
      } else {
        console.error("Eroare la crearea sesiunii de plată");
      }

    } catch (error) {
      console.error("Eroare la efectuarea plății:", error);
    }
  };

  return (
    <button className="paymentButton" onClick={handlePayment} disabled={loading}>
      {loading ? intl.formatMessage({ id: "checkOut.loading", defaultMessage: "se incarca..." }) : intl.formatMessage({ id: "checkOut.pay", defaultMessage: "Plateste" })}
    </button>
  );
};

export default PaymentButton;
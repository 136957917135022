import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import "bootstrap-icons/font/bootstrap-icons.css";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

export const DeleteWifiButton = (props) => {

    const [show, setShow] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleSubmit = (p) => {
        p.preventDefault();
        setSubmitting(true);
    };

    const finishSubmit = async () => {
        const BASE_URL = process.env.REACT_APP_BASE_URL;
        const data = {
            idWifi: props.idWifi,
        };
        // eslint-disable-next-line no-unused-vars
        await Axios.post(`${BASE_URL}/deleteWifi`, data).then((res) => {
        });
    };

    useEffect(() => {
        if (submitting) {
            props.onChanges(counter => counter + 1);
            (async () => {
                await finishSubmit();
            })();
        }
    }, [submitting]);

    return (
        <>
            <Button
                variant="danger"
                onClick={handleShow}
            >
                <i className="bi bi-trash3"></i>
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title>Stergi reteaua <b>{props.network}</b> <br />al {props.hotelName}?</Modal.Title>
                    </Modal.Header>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FormattedMessage id="delWifi.close" defaultMessage="Inchide" />
                        </Button>
                        <Button variant="danger" onClick={handleClose} type="submit">
                            <FormattedMessage id="delWifi.delete" defaultMessage="Sterge" />
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    );
};
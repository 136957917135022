import React, { useState } from "react";
// import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Form, InputGroup } from "react-bootstrap";
import { registerLocale } from "react-datepicker";
import ro from "date-fns/locale/ro";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-datepicker/dist/react-datepicker.css";

import "./index.css";

// Înregistrează limba română
registerLocale("ro", ro);

const RestaurantForm = (props) => {
    const [inputFields, setInputFields] = useState({
        restaurantName: "",
        breakfast: "",
        lunch: "",
        dinner: "",
        others: "",
        idUnitate: "",
    });
    const [errors, setErrors] = useState({});
    // const [successMessage, setSuccessMessage] = useState("");
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    // const BASE_URL = process.env.REACT_APP_BASE_URL;

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.restaurantName) {
            errors.restaurantName = <FormattedMessage id="restaurant.restaurantNameErr" defaultMessage="Adauga denumirea restaurantului" />;
        }
        if (!inputValues.breakfast) {
            errors.breakfast = <FormattedMessage id="restaurant.breakfastErr" defaultMessage="Adauga programul pentru micul dejun" />;
        }
        if (!inputValues.lunch) {
            errors.lunch = <FormattedMessage id="restaurant.lunchErr" defaultMessage="Adauga programul pentru pranz" />;
        }
        if (!inputValues.dinner) {
            errors.dinner = <FormattedMessage id="restaurant.dinnerErr" defaultMessage="Adauga programul pentru cina" />;
        }
        if (inputValues.idUnitate === "0" || !inputValues.idUnitate) {
            errors.idUnitate = <FormattedMessage id="restaurant.selectHotel" defaultMessage="Alege hotelul" />;
        }
        return errors;
    };

    const handleChange = (event) => {
        setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = validateValues(inputFields);
        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            const restaurantData = {
                restaurantName: inputFields.restaurantName,
                breakfast: inputFields.breakfast,
                lunch: inputFields.lunch,
                dinner: inputFields.dinner,
                others: inputFields.others,
                idUnitate: inputFields.idUnitate,
                userId: props.userId
            };

            props.onRestaurantSubmit(restaurantData);

            setInputFields({
                restaurantName: "",
                breakfast: "",
                lunch: "",
                dinner: "",
                others: "",
                idUnitate: "",
            });

            setShowSuccessMessage(true);
            setTimeout(() => {
                setShowSuccessMessage(false);
            }, 2000);
        }
    };

    return (
        <div className="d-flex align-items-center justify-content-center">
            <div className="">
                <Form onSubmit={handleSubmit}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.restaurantName" defaultMessage="Denumire" />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="restaurantName"
                            value={inputFields.restaurantName}
                            onChange={handleChange}
                            className="inputLabel"
                            placeholder="Restaurant Onyx"
                        />
                    </InputGroup>
                    {errors.restaurantName && <p className="error">{errors.restaurantName}</p>}

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.breakfast" defaultMessage="Mic dejun" />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="breakfast"
                            value={inputFields.breakfast}
                            onChange={handleChange}
                            className="inputLabel"
                            placeholder="7-10"
                        />
                    </InputGroup>
                    {errors.breakfast && <p className="error">{errors.breakfast}</p>}

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.lunch" defaultMessage="Pranz" />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="lunch"
                            value={inputFields.lunch}
                            onChange={handleChange}
                            className="inputLabel"
                            placeholder="13-15"
                        />
                    </InputGroup>
                    {errors.lunch && <p className="error">{errors.lunch}</p>}

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.dinner" defaultMessage="Cina" />
                        </InputGroup.Text>
                        <Form.Control
                            type="text"
                            name="dinner"
                            value={inputFields.dinner}
                            onChange={handleChange}
                            className="inputLabel"
                            placeholder="18-21"
                        />
                    </InputGroup>
                    {errors.dinner && <p className="error">{errors.dinner}</p>}

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.others" defaultMessage="Altele" />
                        </InputGroup.Text>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            name="others"
                            value={inputFields.others}
                            onChange={handleChange}
                            className="textAreaStyled"
                        />
                    </InputGroup>

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text id="basic-addon1" style={{ width: 110 }}>
                            <FormattedMessage id="restaurant.hotel" defaultMessage="Hotelul" />
                        </InputGroup.Text>

                        <Form.Select name="idUnitate" value={inputFields.idUnitate} onChange={handleChange}>
                            <option value="0">
                                <FormattedMessage id="restaurant.selectHotel" defaultMessage="Alege hotelul" />
                            </option>
                            {props.hotelsList.map((hotel) => (
                                <option key={hotel.idUnitate} value={hotel.idUnitate}>
                                    {hotel.denumire}
                                </option>
                            ))}
                        </Form.Select>

                    </InputGroup>
                    {errors.idUnitate && <p className="error">{errors.idUnitate}</p>}
                    {!showSuccessMessage ? (
                        <button
                            type="submit"
                            style={{ marginTop: 10 }}
                            className="universalButton buttonUniv btn-rounded waves-effect waves-light"
                        >
                            <FormattedMessage id="restaurant.save" defaultMessage="Salveaza" />
                        </button>
                    ) : (
                        <div style={{ marginTop: 10 }}>
                            <i className="bi bi-check-circle" style={{ color: "green", marginRight: "10px" }}></i>
                            <FormattedMessage id="restaurant.saved" defaultMessage="Restaurant adăugat cu succes!" />
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
};

export default RestaurantForm;

import React from "react";
import { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./index.css";

// import { checkIsLoggedIn } from "../utils/checkIsLoggedIn";
import { EditUnitButton } from "./modal/edit";
import DeleteUnitButton from "./modal/delete";

const GetHotelList = (props) => {
    const [hotelList, setHotelList] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [errors, setErrors] = useState({});
    // const [isLoggedIn, setLoginStatus] = useState(false);
    const [submitedChanges, setSubmitedChanges] = useState(0);

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    // const loggedIn = checkIsLoggedIn();
    // setLoginStatus(loggedIn);
    // if (loggedIn) {
    //     const user = JSON.parse(localStorage.getItem("user"));
    //     setUser(user);
    // }
    const user = JSON.parse(localStorage.getItem("user"));

    const userData = {
        userId: user.id,
    };

    const getHotelList = async (userData) => {
        const response = await Axios.post(`${BASE_URL}/getHotelList`, userData);

        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setHotelList(response.data);
            return response;
        }
    };
    useEffect(() => {
        getHotelList(userData);
    }, [props.onChange, submitedChanges]);

    return (
        <>
            <div className="" style={{ paddingTop: 20 }} >
                <h1 className="text-center">
                    <FormattedMessage
                        id="unitList.ListTitle"
                        defaultMessage="Lista unitatilor mele"
                    />
                </h1>
            </div>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr className="orangeHeader">
                            <th scope="col">#</th>
                            <th scope="col"><FormattedMessage id="unitList.denumire_unitate" defaultMessage="Denumire" /></th>
                            <th scope="col"><FormattedMessage id="unitList.adresa_unitate" defaultMessage="Adresa" /></th>
                            <th scope="col"><FormattedMessage id="unitList.localitate_unitate" defaultMessage="Localitatea" /></th>
                            <th scope="col"><FormattedMessage id="unitList.judet_unitate" defaultMessage="Judetul" /></th>
                            <th scope="col"><FormattedMessage id="unitList.telefon_unitate" defaultMessage="Telefon" /></th>
                            <th scope="col"><FormattedMessage id="unitList.email_unitate" defaultMessage="Email" /></th>
                            <th scope="col"><FormattedMessage id="unitList.checkin" defaultMessage="Check-in" /></th>
                            <th scope="col"><FormattedMessage id="unitList.checkout" defaultMessage="Check-out" /></th>
                            <th scope="col"><FormattedMessage id="unitList.breakfast" defaultMessage="Mic dejun" /></th>
                            <th scope="col"><FormattedMessage id="unitList.lunch" defaultMessage="Pranz" /></th>
                            <th scope="col"><FormattedMessage id="unitList.dinner" defaultMessage="Cina" /></th>
                            <th scope="col"><FormattedMessage id="unitList.societate" defaultMessage="Societate" /></th>
                            <th scope="col"><FormattedMessage id="unitList.cui" defaultMessage="CUI" /></th>
                            <th scope="col"><FormattedMessage id="unitList.nr_reg_com" defaultMessage="Nr.Reg.Com." /></th>
                            <th scope="col"><FormattedMessage id="unitList.adresa_societate" defaultMessage="Adresa societate" /></th>
                            <th scope="col"><FormattedMessage id="unitList.email_societate" defaultMessage="Email societate" /></th>
                            <th scope="col"><FormattedMessage id="unitList.telefon_societate" defaultMessage="Telefon societate" /></th>
                            <th scope="col"><FormattedMessage id="unitList.cont_bancar" defaultMessage="Cont bancar" /></th>
                            <th scope="col"><FormattedMessage id="unitList.banca" defaultMessage="Banca" /></th>
                            <th scope="col"><FormattedMessage id="unitList.reprezentant_legal" defaultMessage="Reprezentant legal" /></th>
                            <th scope="col"><i className="bi bi-three-dots-vertical"></i></th>
                        </tr>
                    </thead>
                    <tbody>
                        {hotelList.map((hotel, index) => {
                            return (
                                <tr key={index}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{hotel.denumire}</td>
                                    <td>{hotel.adresa}</td>
                                    <td>{hotel.localitatea}</td>
                                    <td>{hotel.judetul}</td>
                                    <td>{hotel.telefon}</td>
                                    <td>{hotel.email}</td>
                                    <td>{hotel.checkIn}</td>
                                    <td>{hotel.checkOut}</td>
                                    <td>{hotel.breakfast}</td>
                                    <td>{hotel.lunch}</td>
                                    <td>{hotel.dinner}</td>
                                    <td>{hotel.societate}</td>
                                    <td>{hotel.cui}</td>
                                    <td>{hotel.nrc}</td>
                                    <td>{hotel.adresa_societate}</td>
                                    <td>{hotel.email_societate}</td>
                                    <td>{hotel.telefon_societate}</td>
                                    <td>{hotel.cont_bancar}</td>
                                    <td>{hotel.banca}</td>
                                    <td>{hotel.reprezentant_legal}</td>
                                    <td className="button-container">

                                        {
                                            <EditUnitButton
                                                denumire={hotel.denumire}
                                                adresa={hotel.adresa}
                                                localitatea={hotel.localitatea}
                                                judetul={hotel.judetul}
                                                telefon={hotel.telefon}
                                                email={hotel.email}
                                                checkIn={hotel.checkIn}
                                                checkOut={hotel.checkOut}
                                                breakfast={hotel.breakfast}
                                                lunch={hotel.lunch}
                                                dinner={hotel.dinner}
                                                societate={hotel.societate}
                                                cui={hotel.cui}
                                                nrc={hotel.nrc}
                                                adresaSocietate={hotel.adresa_societate}
                                                telefonSocietate={hotel.telefon_societate}
                                                emailSocietate={hotel.email_societate}
                                                contBancar={hotel.cont_bancar}
                                                banca={hotel.banca}
                                                reprezentantLegal={hotel.reprezentant_legal}
                                                idUnitate={hotel.idUnitate}
                                                userId={hotel.userId}
                                                onSubmitedChanges={setSubmitedChanges}
                                                className="button"
                                            />}
                                        {<DeleteUnitButton
                                            idUnitate={hotel.idUnitate}
                                            userId={hotel.userId}
                                            denumire={hotel.denumire}
                                            className="button"
                                            onSubmitedChanges={setSubmitedChanges}
                                        />}
                                    </td>
                                </tr>
                            );
                        })}

                    </tbody>
                </table>
            </div>
        </>
    );
};

export default GetHotelList;

import React, { useState } from "react";
import { IntlProvider } from "react-intl";
import Romanian from "./languages/ro.json";
import English from "./languages/en.json";

export const Context = React.createContext();
const local = navigator.language;

let lang;
if (local === "ro-RO" || local === "ro") {
    lang = Romanian;
} else {
    if (local === "en" || local === "en-US") {
        lang = English;
    }
    //  else {
    //      lang = Arabic;
    //  }
}
const Wrapper = (props) => {
    const [locale, setLocale] = useState(local);
    const [messages, setMessages] = useState(lang);

    const selectLanguage = (e) => {
        const newLocale = e.target.value;

        setLocale(newLocale);
        if (newLocale === "ro") {
            setMessages(Romanian);
        } else {
            if (newLocale === "en") {
                setMessages(English);
            }
            //  else {
            //      setMessages(Arabic);
            //  }
        }
    };

    return (
        <Context.Provider value={{ locale, selectLanguage }}>
            <IntlProvider messages={messages} locale={locale}>
                {props.children}
            </IntlProvider>
        </Context.Provider>
    );
};
export default Wrapper;
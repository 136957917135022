import React from "react";
import { FormattedMessage } from "react-intl";

import GetHotelListByUserId from "../utils/getHotelsListByUserId";
import GetInvoices from "./invoices";
import Menu from "navigation/menu";
import TopMenu from "navigation/topMenu";
import PieChartWithCustomizedLabel from "./pieChart";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import FooterMenu from "navigation/footer";

const Financial = () => {
  const [selectedHotel, setSelectedHotel] = React.useState(null);

  return (
    <div>
      <TopMenu />
      <Menu />
      <div className="firstFinancialContainer">
        <div className="financialContainer" data-aos="fade-in">
          <h2>
            <FormattedMessage id="financial.title" defaultMessage="Financiar" />
          </h2>
          <h6>
            <FormattedMessage id="financial.subtitle" defaultMessage="tot ce iti dorestii sa stii despre partea financiara" />
          </h6>
        </div>
        <div className="financialFormContainer">
          <GetHotelListByUserId onChangeHotel={setSelectedHotel} />
          <GetInvoices selectedHotel={selectedHotel} />
        </div><br />
        {selectedHotel && (
          <div className="financialChartContainer" >
            <PieChartWithCustomizedLabel selectedHotel={selectedHotel} />
          </div>
        )}
      </div>
      <FooterMenu />
    </div>
  );
};

export default Financial;
import React from "react";
import "./index.css";

const CardRoom = ({ hotelName, roomName, numarSesizari }) => {
  const roomClass = numarSesizari > 0 ? "room notification" : "room free";

  return (
    <div className={roomClass}>
      <div className="hotel-name">{hotelName}</div>
      <div className="room-name">Camera {roomName}</div>
      <div className="room-sesizari-container">
        {numarSesizari > 0 && (
          <div className="room-sesizari-bulina">
            {numarSesizari}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardRoom;

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Form } from "react-bootstrap";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

export const EditQrButton = (props) => {
    const [camera, setCamera] = useState(props.camera);
    const [hotel, setHotel] = useState(props.idHotel);
    const [hotelList, setHotelList] = useState([]);
    const [message, setMessage] = useState("");
    const [errors, setErrors] = useState({});
    const [show, setShow] = useState(false);

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const user = JSON.parse(localStorage.getItem("user"));

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: user.id }).then((response) => {
            setHotelList(response.data);
        });
    }, []);

    const handleEdit = async (event) => {
        event.preventDefault();
        const dataToSend = {
            cameraId: props.cameraId,
            denumireCamera: camera,
            idHotel: hotel,
            userId: user.id
        };

        const response = await Axios.post(`${BASE_URL}/editQrCode`, dataToSend);
        if (response.data.error) {
            setErrors({ response: response.data.error });
        } else {
            setMessage(response.data.message);
            props.onSubmitedChanges(counter => counter + 1);
            handleClose();
            return response;
        }
    };

    return (
        <>
            <Button
                variant="primary"
                onClick={handleShow}
            >
                <i className="bi bi-pencil-square"></i>
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Form onSubmit={handleEdit}>
                    <Modal.Header closeButton>
                        <Modal.Title><FormattedMessage id="editQrCode.title" defaultMessage="Editeaza QR code-ul camerei " /> {props.camera}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label><FormattedMessage id="editQrCode.camera" defaultMessage="Camera" /></Form.Label>
                            <Form.Control
                                type="text"
                                name="camera"
                                defaultValue={props.camera}
                                onChange={(e) => setCamera(e.target.value)}
                            /><br />
                            {errors.camera && <p className="error">{errors.camera}</p>}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label><FormattedMessage id="editQrCode.hotel" defaultMessage="Hotel" /></Form.Label>
                            <Form.Control
                                as="select"
                                name="hotel"
                                defaultValue={props.idHotel}
                                onChange={(e) => setHotel(e.target.value)}
                            >
                                <option value="0"><FormattedMessage id="editQrCode.selectHotel" defaultMessage="Selecteaza hotelul" /></option>
                                {hotelList.map((val, key) => (
                                    <option key={key} value={`${val.idUnitate}`} selected={props.idHotel === val.idUnitate}>{val.denumire}</option>
                                ))}
                            </Form.Control><br />
                            {errors.hotel && <p className="error">{errors.hotel}</p>}
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            <FormattedMessage id="editQrCode.close" defaultMessage="Inchide" />
                        </Button>
                        <Button variant="success" onClick={handleClose} type="submit">
                            <FormattedMessage id="editQrCode.save" defaultMessage="Salveaza" />
                        </Button>
                    </Modal.Footer>
                    {message && <p className="success">{message}</p>}
                </Form>
            </Modal>
        </>
    );
};
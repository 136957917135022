import React, { useState, useContext } from "react";
import Axios from "axios";
import { UserContext } from "../../App"; // Asigură-te că calea este corectă
import { Link, useNavigate } from "react-router-dom";
import "./index.css"; // Importă CSS-ul pentru Login

const Login = () => {
    const [inputFields, setInputFields] = useState({
        email: "",
        password: "",
    });
    const [errors, setErrors] = useState({});
    const { setUser } = useContext(UserContext);
    const navigate = useNavigate();

    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const handleChange = (event) => {
        setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value
        });
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.email) {
            errors.email = "Email is required";
        }
        if (!inputValues.password) {
            errors.password = "Password is required";
        }
        return errors;
    };

    const handleSubmit = () => {
        const errors = validateValues(inputFields);
        if (Object.keys(errors).length > 0) {
            setErrors(errors);
        } else {
            const userData = {
                email: inputFields.email,
                password: inputFields.password,
            };
            Axios.post(`${BASE_URL}/login`, userData)
                .then((response) => {
                    if (response.data.error) {
                        setErrors({ response: response.data.error });
                    } else {
                        localStorage.setItem("user", JSON.stringify(response.data));
                        setUser(response.data); // Actualizează contextul
                        setErrors({});
                        navigate("/members/dashboard", { replace: true });
                    }
                })
                .catch((error) => {
                    setErrors({ response: error.message });
                });
        }
    };

    return (
        <div className="login__container__loginWith">
            <div className="login__container">
                <h1 className="login__container__title">Autentificare</h1>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="email"
                            type="text"
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: errors.email ? "1px solid red" : null }}
                        />
                        <small className="form-text text-muted error">{errors.email}</small>
                    </div>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: errors.password ? "1px solid red" : null }}
                        />
                        <small className="form-text text-muted error">{errors.password}</small>
                        <label htmlFor="exampleInputEmail1"></label>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Intră în cont</button>
                </form>
                <div className="login__container__register">
                    <p>Nu ai cont? <Link to="/register">Înregistrează-te</Link></p>
                </div>
            </div>
        </div>
    );
};

export default Login;

import React from "react";
import Menu from "./navigation/menu";
import TopMenu from "./navigation/topMenu";

const HomePage = () => {
    return (
        <>
            <TopMenu />
            <Menu /><br /><br />

            <div style={{ height: "100%", justifyContent: "center", alignItems: "center" }}>
                <h1 style={{ color: "#ff0000" }}> IN CONSTRUCTIE - vs 5.0</h1><br /><br />
                <h1>meniu digital cu cod qr</h1><br /><br />
                <h2>Deții un hotel, pensiune, motel, cabană sau apartament?</h2> <br />

                <h4>Ușurează viața clienților tăi! <br />Cu un simplu scan al unui cod QR din camera lor, ei pot solicita rapid servicii suplimentare: prosoape curate, săpun, remedierea unor probleme tehnice sau chiar prelungirea sejurului. <br />O experiență mai simplă pentru ei, o gestionare mai eficientă pentru tine!</h4><br />
                <h2>Versiunea 4.9 este cea functionala actuala si o puteti gasi la adresa: </h2>
                {/* <a href="https://www.hotel-qr.ro">https://www.hotel-qr.ro</a> */}
                <h2><a href="https://qrAlert.ro">https://qrAlert.ro</a></h2>
            </div>

        </>
    );
};

export default HomePage;
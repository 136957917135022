import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { Form, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const CreateQrCode = (props) => {
    const [inputFields, setinputFields] = useState({
        camera: "",
        hotel: "",
    });
    const [errors, setErrors] = useState({});
    const [hotelList, setHotelList] = useState([]);
    const [message, setMessage] = useState("");

    const userData = JSON.parse(localStorage.getItem("user"));
    const BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const nextStep = () => {
        navigate("/members/payedOptions");
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.camera) {
            errors.camera = <FormattedMessage id="addNewRoom.error1" defaultMessage="Denumirea camerei este obligatorie" />;
        }
        if (!inputValues.hotel) {
            errors.hotel = <FormattedMessage id="addNewRoom.error2" defaultMessage="Selecteaza hotelul" />;
        }
        return errors;
    };

    useEffect(() => {
        Axios.post(`${BASE_URL}/getHotelList`, { userId: userData.id }).then((response) => {
            setHotelList(response.data);
        });
    }, []);

    const handleChange = (event) => {
        setinputFields({
            ...inputFields,
            [event.target.name]: event.target.value,
        });
    };

    const addNewRoom = (e) => {
        e.preventDefault();
        setErrors(validateValues({ camera: inputFields.camera, hotel: inputFields.hotel }));
        Axios.post(`${BASE_URL}/addNewRoom`, {
            camera: inputFields.camera,
            hotel: inputFields.hotel,
            userId: userData.id,
        }).then((response) => {
            setMessage(response.data.message);
        });
        props.setSubmitedChanges1(counter => counter + 1);
    };

    return (
        <div className="formContent  d-flex align-items-center justify-content-center">
            <div className="">
                <h1 className="text-center"><FormattedMessage id="addNewRoom.title" defaultMessage="Adaugă o cameră nouă" /></h1>
                <hr />
                <Form onSubmit={addNewRoom}>
                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text style={{ width: 110 }}><FormattedMessage id="addNewRoom.camera" defaultMessage="Camera nr." /></InputGroup.Text>
                        <Form.Control type="text" name="camera" onChange={handleChange} />
                        {errors.camera && <p className="error">{errors.camera}</p>}
                    </InputGroup>

                    <InputGroup style={{ marginTop: 10 }}>
                        <InputGroup.Text style={{ width: 110 }}><FormattedMessage id="addNewRoom.hotel" defaultMessage="Hotel" /></InputGroup.Text>
                        <Form.Control as="select" name="hotel" onChange={handleChange}>
                            <option value=""><FormattedMessage id="addNewRoom.selectHotel" defaultMessage="Selecteaza hotelul" /></option>
                            {hotelList.length === 1 && <option value={hotelList[0].idUnitate} selected>{hotelList[0].denumire}</option>}

                            {hotelList.length > 1 && hotelList.map((val, key) => {
                                return (
                                    <option key={key} value={val.idUnitate}>{val.denumire}</option>
                                );
                            })}
                        </Form.Control>
                        {errors.hotel && <p className="error">{errors.hotel}</p>}
                    </InputGroup>
                    <button type="submit" style={{ marginTop: 10 }} className="universalButton buttonUniv btn-rounded waves-effect">
                        <FormattedMessage id="addNewRoom.submit" defaultMessage="Adauga" />
                    </button>
                    <button className="universalButtonGreen buttonUnivGreen btn-rounded waves-effect" onClick={nextStep}>
                        <FormattedMessage
                            id="addNewRoom.ultimulPas"
                            defaultMessage="Ultimul pas"
                        />
                    </button>
                </Form>
                {message && <p className="success">{message}</p>}
            </div>
        </div>
    );
};

export default CreateQrCode;
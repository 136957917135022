import React from "react";

const formatTwoDigits = (number) => (number < 10 ? `0${number}` : number);

export const dateParser = (myString) => {
  const dateObject = new Date(myString);
  const day = dateObject.getDate();
  const month = formatTwoDigits(dateObject.getMonth() + 1);
  const year = dateObject.getFullYear();
  const formattedDate = `${day}-${month}-${year}`;

  return (
    <>
      {formattedDate}
    </>
  );
};
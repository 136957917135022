import React, { useState } from "react";
import Axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import "./index.css";

const RegisterComponent = () => {
    const [errors, setErrors] = useState("");
    // eslint-disable-next-line no-unused-vars
    const [isLoggedIn, setLoginStatus] = useState(false);
    const [inputFields, setInputFields] = useState({
        email: "",
        password: "",
        nume: "",
        prenume: "",
    });

    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();

    const handleChange = async (event) => {
        event.preventDefault();
        setInputFields({
            ...inputFields,
            [event.target.name]: event.target.value
        });
    };

    const validateValues = (inputValues) => {
        let errors = {};
        if (!inputValues.email) {
            errors.email = "Adresa de email este ceruta!";
        }
        if (!inputValues.password) {
            errors.password = "Parola este ceruta!";
        }
        if (inputValues.password.length < 6) {
            errors.passwordLength = "Parola trebuie sa contina minim 6 caractere!";
        }
        if (inputValues.email) {
            const re = /\S+@\S+\.\S+/;
            if (!re.test(inputValues.email)) {
                errors.invalidEmail = "Adresa de email nu este valida!";
            }
        }
        if (!inputValues.nume) {
            errors.nume = "Numele este cerut!";
        }
        if (!inputValues.prenume) {
            errors.prenume = "Prenumele este cerut!";
        }
        if (inputValues.nume) {
            const re = /^[a-zA-Z]+$/;
            if (!re.test(inputValues.nume)) {
                errors.invalidName = "Numele nu este valid!";
            }
        }
        if (inputValues.prenume) {
            const re = /^[a-zA-Z]+$/;
            if (!re.test(inputValues.prenume)) {
                errors.invalidPrenume = "Prenumele nu este valid!";
            }
        }
        return errors;
    };

    const onSubmit = async () => {
        const userDataVerify = {
            email: inputFields.email,
        };

        await Axios.post(`${BASE_URL}/verifyEmail`, userDataVerify).then((response) => {
            if (response.data.length > 0) {
                setErrors({ verifyEmail: response.data });
                return;
            } else {
                const errors = validateValues(inputFields);
                const userData = {
                    email: inputFields.email,
                    password: inputFields.password,
                    nume: inputFields.nume,
                    prenume: inputFields.prenume,
                };

                if (Object.keys(errors).length > 0) {
                    setErrors(errors);
                } else {
                    Axios.post(`${BASE_URL}/addUser`, userData)
                        .then((response) => {
                            if (response.data.error) {
                                setErrors({ response: response.data.error });
                            } else {
                                setLoginStatus(true);
                                setErrors({});
                                navigate("/login", { replace: true });
                            }
                        });
                }

            }
        })
            .catch((error) => {
                setErrors({ response: error });
            });
    };

    return (
        <div className="register__container__loginWith">
            <div className="register__container">
                <h1 className="register__container__title">Înregistrare</h1>
                <form>
                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="email"
                            placeholder="Email"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: (errors.email || errors.verifyEmail) ? "1px solid red" : null }}
                        />
                        <small id="emailHelp" className="form-text text-muted error">{errors.email || errors.verifyEmail}</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: errors.password ? "1px solid red" : null }}
                        />
                        <small id="passHelp" className="form-text text-muted error">{errors.password || errors.passwordLength}</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="nume"
                            placeholder="Nume"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: errors.nume ? "1px solid red" : null }}
                        />
                        <small id="passHelp" className="form-text text-muted error">{errors.nume || errors.invalidName}</small>
                    </div>

                    <div className="form-group">
                        <label htmlFor="exampleInputEmail1"></label>
                        <input
                            name="prenume"
                            placeholder="Prenume"
                            className="form-control"
                            onChange={handleChange}
                            style={{ border: errors.prenume ? "1px solid red" : null }}
                        />
                        <small id="passHelp" className="form-text text-muted error">{errors.prenume || errors.invalidPrenume}</small>
                        <label htmlFor="exampleInputEmail1"></label>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={onSubmit} >Trimite</button>
                </form>
                <div className="register__container__loginWith__register">
                    {/* <p>Ai deja cont? <a href="/login">Autentifică-te</a></p> */}
                    <p>Ai deja cont? <Link to="/login">Autentifică-te</Link></p>
                </div>
            </div>
        </div>

    );
};

export default RegisterComponent;
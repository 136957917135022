import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Axios from "axios";
import { FormattedMessage } from "react-intl";

const DeleteReviewButton = ({ review, onSubmitedChanges }) => {
  const [show, setShow] = useState(false);
  //eslint-disable-next-line no-unused-vars
  const [errors, setErrors] = useState({});

  const user = JSON.parse(localStorage.getItem("user"));
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const handleDelete = () => {
    const data = {
      idReview: review.idReview,
      userId: user.id,
    };

    Axios.post(`${BASE_URL}/deleteReview`, data)
      .then((response) => {
        if (response.data.error) {
          setErrors({ response: response.data.error });
        } else {
          onSubmitedChanges(submitedChanges => submitedChanges + 1);
          setShow(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Button variant="danger" onClick={() => setShow(true)}>
        <i className="bi bi-trash3"></i>
      </Button>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title><FormattedMessage id="delReview.deleteReview" defaultMessage="Sterge recenzia" /></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormattedMessage id="delReview.delConfirm" defaultMessage="Esti sigur ca vrei sa stergi recenzia?" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            <FormattedMessage id="delReview.close" defaultMessage="Inchide" />
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            <FormattedMessage id="delReview.delete" defaultMessage="Sterge" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DeleteReviewButton;